function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export default ((unsortedNodes, fromFooter) => {
  //we need to clone data because this variable is used in useMemo as dependency
  //when sorting, it causes mutability of referenced variable which causes infinite reload
  var clonedNodes = [...unsortedNodes];
  var nodes = clonedNodes.sort((el1, el2) => el1.parent_id - el2.parent_id || el1.position - el2.position);
  var recursivelyFillChild = id => {
    var items = nodes.filter(el => el.parent_id == id);
    return items.map(item => {
      var childObj = recursivelyFillChild(item.id);
      var left = childObj.find(el => el.name == 'leftInner');
      var right = childObj.find(el => el.name == 'rightInner');
      var actions = childObj.find(el => el.name == "actions");
      var publicity = childObj.find(el => el.name == "publicity");
      if (left || right || actions || publicity) {
        if (fromFooter) {
          return _objectSpread(_objectSpread({}, item), {}, {
            left: left && left.right,
            // since the default child is left
            // the rest elements will be stored there
            right: right && right.right,
            actions: actions && actions.right,
            publicity: publicity && publicity.right
          });
        }
        return _objectSpread(_objectSpread({}, item), {}, {
          left: left && left.left,
          // since the default child is left
          // the rest elements will be stored there
          right: right && right.left,
          actions: actions && actions.left,
          publicity: publicity && publicity.left
        });
      }

      //default branch is the left one
      if (fromFooter) {
        return _objectSpread(_objectSpread({}, item), {}, {
          left: [],
          right: childObj
        });
      }
      return _objectSpread(_objectSpread({}, item), {}, {
        right: [],
        left: childObj
      });
    });
  };

  //passing null first parent_id because first parent elements does not have parent_id
  return recursivelyFillChild(null);
});