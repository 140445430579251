function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
/**
 * Persistence layer with expiration based on localStorage.
 */

class NamespacedLocalStorage {
  constructor(localStorage, key) {
    this.localStorage = localStorage;
    this.key = key;
  }
  _makeKey(key) {
    return "".concat(this.key, "__").concat(key);
  }
  getItem(name) {
    return this.localStorage.getItem(this._makeKey(name));
  }
  setItem(name, value) {
    return this.localStorage.setItem(this._makeKey(name), value);
  }
  removeItem(name) {
    return this.localStorage.removeItem(this._makeKey(name));
  }
}
export default class BrowserPersistence {
  /* istanbul ignore next: test injects localstorage mock */
  constructor() {
    var localStorage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.localStorage;
    this.storage = new NamespacedLocalStorage(localStorage, this.constructor.KEY || BrowserPersistence.KEY);
  }
  getRawItem(name) {
    return this.storage.getItem(name);
  }
  getItem(name) {
    var now = Date.now();
    var item = this.storage.getItem(name);
    if (!item) {
      return undefined;
    }
    var {
      value,
      ttl,
      timeStored
    } = JSON.parse(item);
    if (ttl && now - timeStored > ttl * 1000) {
      this.storage.removeItem(name);
      return undefined;
    }
    return value && JSON.parse(value);
  }
  setItem(name, value, ttl) {
    var timeStored = Date.now();
    this.storage.setItem(name, JSON.stringify({
      value: JSON.stringify(value),
      timeStored,
      ttl
    }));
  }
  removeItem(name) {
    this.storage.removeItem(name);
  }
}
_defineProperty(BrowserPersistence, "KEY", 'M2_VENIA_BROWSER_PERSISTENCE');