import { useEffect } from 'react';
import { BrowserPersistence } from '@magento/peregrine/lib/util';
import { getStoreCode } from "@magento/venia-ui/lib/components/StoreCodeRoute/storeCodeRoute";
import useStorefrontLiveSearch, { DEFAULT_SEARCH_UNIT_ID, mapProductToTemplate } from "../StorefrontLiveSearch/useStorefrontLiveSearch";
import { useAppContext } from "../../context/app";
import { useSearchBar } from "../SearchBar";
var storage = new BrowserPersistence();
export var useAutocompleteSearch = props => {
  var storeCode = getStoreCode();
  var listRecentSearches = storage.getItem("recent_searches_".concat(storeCode));
  var [{
    searchBarValue,
    openSearchBar
  }, {
    setOpenSearchBar
  }] = useAppContext();
  var {
    handleSuggestion,
    validatedSearchValue
  } = useSearchBar();
  var {
    debouncedProductSearchFetch,
    productSearchData,
    productSearchLoading,
    mseProductClick,
    mseSuggestionClick,
    mseCategoryClick
  } = useStorefrontLiveSearch({
    debounceTimeout: 10,
    searchUnitId: 'livesearch-popover',
    includeSuggestions: true
  });
  useEffect(() => {
    var validatedSearchField = validatedSearchValue(searchBarValue);
    if (openSearchBar && !validatedSearchField) {
      setOpenSearchBar(false);
    } else if (!openSearchBar && validatedSearchField) {
      setOpenSearchBar(true);
    }
  }, [searchBarValue]);
  useEffect(() => {
    if (openSearchBar && searchBarValue) {
      debouncedProductSearchFetch({
        phrase: searchBarValue || '',
        sort: [{
          attribute: "inStock",
          direction: "DESC"
        }, {
          attribute: 'relevance',
          direction: 'DESC'
        }]
      });
    }
  }, [searchBarValue, openSearchBar]);
  var items = mapProductToTemplate(productSearchData === null || productSearchData === void 0 ? void 0 : productSearchData.items);
  var popularSearch = (productSearchData === null || productSearchData === void 0 ? void 0 : productSearchData.related_terms) || [];
  var suggestions = (productSearchData === null || productSearchData === void 0 ? void 0 : productSearchData.suggestions) || [];
  var totalResultsFound = (productSearchData === null || productSearchData === void 0 ? void 0 : productSearchData.total_count) || 0;
  useEffect(() => {
    if (openSearchBar && !productSearchLoading && (!items || !items.length)) {
      setOpenSearchBar(false);
    }
  }, [productSearchLoading]);
  return {
    items,
    productSearchLoading,
    popularSearch,
    totalResultsFound,
    suggestions,
    listRecentSearches,
    handleSuggestion,
    mseProductClick,
    mseSuggestionClick,
    mseCategoryClick
  };
};