import { getContentTypeConfig } from './config';

/**
 * Create a basic object representing a content type in our tree
 *
 * @param type
 * @param node
 * @returns {{appearance: any, children: Array, contentType: *}}
 */
var createContentTypeObject = (type, node) => {
  return {
    contentType: type,
    appearance: node ? node.getAttribute('data-appearance') : null,
    children: []
  };
};

/**
 * Walk over tree nodes extracting each content types configuration
 *
 * @param {Node} rootEl
 * @param {Object} contentTypeStructureObj
 * @returns {Object}
 */
var walk = (rootEl, contentTypeStructureObj) => {
  var tree = document.createTreeWalker(rootEl, NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_TEXT);
  var currentNode = tree.nextNode();
  while (currentNode) {
    if (currentNode.nodeType !== Node.ELEMENT_NODE) {
      currentNode = tree.nextNode();
      continue;
    }
    var contentType = currentNode.getAttribute('data-content-type');
    if (!contentType) {
      currentNode = tree.nextNode();
      continue;
    }
    var props = createContentTypeObject(contentType, currentNode);
    var contentTypeConfig = getContentTypeConfig(contentType);
    if (contentTypeConfig && typeof contentTypeConfig.configAggregator === 'function') {
      try {
        Object.assign(props, contentTypeConfig.configAggregator(currentNode, props));
      } catch (e) {
        console.error("Failed to aggregate config for content type ".concat(contentType, "."), e);
      }
    } else {
      console.warn("Page Builder ".concat(contentType, " content type is not supported, this content will not be rendered."));
    }
    contentTypeStructureObj.children.push(props);
    walk(currentNode, props);
    currentNode = tree.nextSibling();
  }
  return contentTypeStructureObj;
};
var pbStyleAttribute = 'data-pb-style';
var bodyId = 'html-body';

/**
 * Convert styles block to inline styles.
 * @param {HTMLDocument} document
 */
var convertToInlineStyles = document => {
  var styleBlocks = document.getElementsByTagName('style');
  var styles = {};
  if (styleBlocks.length > 0) {
    Array.from(styleBlocks).forEach(styleBlock => {
      var cssRules = styleBlock.sheet.cssRules;
      Array.from(cssRules).forEach(rule => {
        if (rule instanceof CSSStyleRule) {
          var selectors = rule.selectorText.split(',').map(selector => selector.trim());
          selectors.forEach(selector => {
            if (!styles[selector]) {
              styles[selector] = [];
            }
            styles[selector].push(rule.style);
          });
        }
      });
    });
  }
  Object.keys(styles).map(selector => {
    var element = document.querySelector(selector);
    if (!element) {
      return;
    }
    styles[selector].map(style => {
      element.setAttribute('style', element.style.cssText + style.cssText);
    });
    element.removeAttribute(pbStyleAttribute);
  });
};

/**
 * Parse the master format storage HTML
 *
 * @param {String} htmlStr
 * @returns {Object}
 */
var parseStorageHtml = htmlStr => {
  var container = new DOMParser().parseFromString(htmlStr, 'text/html');
  var stageContentType = createContentTypeObject('root-container');
  container.body.id = bodyId;
  convertToInlineStyles(container);
  return walk(container.body, stageContentType);
};
export default parseStorageHtml;