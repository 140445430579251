export default (node => {
  var title = node.querySelector('[data-element="title"]');
  var description = node.querySelector('[data-element="description"]');
  var desktop_image = node.querySelector('[data-element="desktop_image"]');
  var mobile_image = node.querySelector('[data-element="mobile_image"]');
  var data = node.getAttribute('data-careers');
  var section_title = node.querySelector('[data-element="section_title"]');
  var section_footer = node.querySelector('[data-element="section_footer"]');
  return {
    title: title && title.innerHTML,
    sectionTitle: section_title && section_title.innerHTML,
    sectionFooter: section_footer && section_footer.innerHTML,
    description: description && description.innerHTML,
    desktopImage: desktop_image && desktop_image.getAttribute('src'),
    mobileImage: desktop_image && mobile_image.getAttribute('src'),
    data: data && JSON.parse(data)
  };
});