export default (node => {
  var products = node.getAttribute('data-products');
  var title = node.querySelector('[data-element="title"]');
  var linkText = node.querySelector('[data-element="main_link_text"]');
  var linkHref = node.querySelector('a[data-element="main_link"]');
  var imageSrc = node.querySelector('img[data-element="image"]');
  var imageMobileSrc = node.querySelector('img[data-element="image_mobile"]');
  var imageTitle = node.querySelector('[data-element="image_title"]');
  var imgLinkText = node.querySelector('[data-element="image_link_text"]');
  var imageHref = node.querySelector('a[data-element="image_link"]');
  var data_products = JSON.parse(products);
  var skus = data_products === null || data_products === void 0 ? void 0 : data_products.products.map(item => {
    var _item$product_link;
    return (_item$product_link = item.product_link) === null || _item$product_link === void 0 ? void 0 : _item$product_link.product_sku;
  });
  return {
    title: title ? title.innerHTML : null,
    linkText: linkText ? linkText.textContent : null,
    linkHref: linkHref ? linkHref.getAttribute('href') : null,
    imageSrc: imageSrc ? imageSrc.getAttribute('src') : null,
    imageMobileSrc: imageMobileSrc ? imageMobileSrc.getAttribute('src') : null,
    imgLinkText: imgLinkText ? imgLinkText.textContent : null,
    imageTitle: imageTitle ? imageTitle.innerHTML : null,
    imageHref: imageHref ? imageHref.getAttribute('href') : null,
    skus
  };
});