/**
 * Resolve link properties
 *
 * @param {string} link
 */
export default (link => {
  var isExternalUrl;
  var linkProps = {};
  try {
    var baseUrlObj = new URL(process.env.MAGENTO_BACKEND_URL);
    var urlObj = new URL(link);
    isExternalUrl = baseUrlObj.host !== urlObj.host;
    if (isExternalUrl) {
      linkProps['href'] = link;
    } else {
      linkProps['to'] = urlObj.pathname.replace('/en/', '/').replace('/fr/', '/');
    }
  } catch (e) {
    linkProps['href'] = link;
  }
  return linkProps;
});