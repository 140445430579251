export default (node => {
  var title = node.querySelector('[data-cms-title="Title"]');
  var videoLink = node.querySelector('[data-cms-title="Block 1 Video Source"]');
  var articTitle = node.querySelector('[data-cms-title="Block 1 Article Title"]');
  var articleDescription = node.querySelector('[data-cms-title="Block 1 Description"]');
  var articleLink = node.querySelector('a[data-element="block_1_button_link"]');
  var articleBtnText = node.querySelector('[data-cms-title="Block 1 Button Text"]');
  var articleBtnColor = node.querySelector('[data-cms-title="Block 1 Button Text Color"]');
  var articleBackgroundBtnColor = node.querySelector('[data-cms-title="Block 1 Button Background Color"]');
  var articleImage = node.querySelector('img[data-cms-title="Block 1 Desktop Image"]');
  var articleImageMobile = node.querySelector('img[data-cms-title="Block 1 Mobile Image"]');
  var videoLink2 = node.querySelector('[data-cms-title="Block 2 Video Source"]');
  var articTitle2 = node.querySelector('[data-cms-title="Block 2 Article Title"]');
  var articleDescription2 = node.querySelector('[data-cms-title="Block 2 Description"]');
  var articleLink2 = node.querySelector('a[data-element="block_2_button_link"]');
  var articleBtnText2 = node.querySelector('[data-cms-title="Block 2 Button Text"]');
  var articleBtnColor2 = node.querySelector('[data-cms-title="Block 2 Button Text Color"]');
  var articleBackgroundBtnColor2 = node.querySelector('[data-cms-title="Block 2 Button Background Color"]');
  var articleImage2 = node.querySelector('img[data-cms-title="Block 2 Desktop Image"]');
  var articleImageMobile2 = node.querySelector('img[data-cms-title="Block 2 Mobile Image"]');
  return {
    title: title.innerHTML,
    videoLink: videoLink.innerHTML,
    articTitle: articTitle.innerHTML,
    articleDescription: articleDescription.innerHTML,
    articleLink: articleLink ? articleLink.getAttribute('href') : null,
    articleBtnText: articleBtnText.innerHTML,
    articleBtnColor: articleBtnColor.innerHTML,
    articleBackgroundBtnColor: articleBackgroundBtnColor.innerHTML,
    articleImage: articleImage ? articleImage.getAttribute('src') : null,
    articleImageMobile: articleImageMobile ? articleImageMobile.getAttribute('src') : null,
    videoLink2: videoLink2.innerHTML,
    articTitle2: articTitle2.innerHTML,
    articleDescription2: articleDescription2.innerHTML,
    articleLink2: articleLink2 ? articleLink2.getAttribute('href') : null,
    articleBtnText2: articleBtnText2.innerHTML,
    articleBtnColor2: articleBtnColor2.innerHTML,
    articleBackgroundBtnColor2: articleBackgroundBtnColor2.innerHTML,
    articleImage2: articleImage2 ? articleImage2.getAttribute('src') : null,
    articleImageMobile2: articleImageMobile2 ? articleImageMobile2.getAttribute('src') : null

    // buttonTitle2: buttonTitle2 ? buttonTitle2 : null
  };
});