var _excluded = ["type"];
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
import { BrowserPersistence } from '@magento/peregrine/lib/util';
import { getStoreCode } from "@magento/venia-ui/lib/components/StoreCodeRoute/storeCodeRoute";
import { isLinkExternal } from "@magento/venia-ui/lib/components/BBLink/bbLink";
var storage = new BrowserPersistence();
// If the root template supplies the backend URL at runtime, use it directly
var htmlDataset = document.querySelector('html').dataset;
var {
  imageOptimizingOrigin
} = htmlDataset;
// Protect against potential falsy values for `mediaBackend`.
var storeCode = getStoreCode();
var storeSecureBaseMediaUrl = {};
// Fallback to global secure_base_media_url set at build time
AVAILABLE_STORE_VIEWS.forEach(store => {
  storeSecureBaseMediaUrl[store.code] = store.secure_base_media_url;
});
var mediaBackend = storage.getItem('store_view_secure_base_media_url') || storeSecureBaseMediaUrl[storeCode];
if (!mediaBackend) {
  console.warn('A media backend URL should be defined in your config.');
  mediaBackend = 'https://backend.test/media/';
}
var useBackendForImgs = imageOptimizingOrigin === 'backend';
// Tests if a URL begins with `http:` or `https:` or `data:`
var absoluteUrl = /^(data|http|https)?:/i;
// Simple path joiner that guarantees one and only one slash between segments
var joinUrls = (base, url) => (base.endsWith('/') ? base.slice(0, -1) : base) + '/' + (url && typeof url === 'string' && url.startsWith('/') ? url.slice(1) : url);
var mediaBases = new Map().set('image-product', 'catalog/product/').set('image-category', 'catalog/category/').set('image-swatch', 'attribute/swatch/');
var getFileType = url => {
  var fileName = url.pathname.split('/').reverse()[0];
  var fileType = fileName.split('.').reverse()[0];
  return fileType;
};
var MAGENTO_BACKEND_URL = process.env.MAGENTO_BACKEND_URL;
/**
 * Creates an "optimized" url for a provided relative url based on
 * requested media type and width. Any image URLs (whose type begins with
 * "image-" will also be optimized.)
 *
 * If a `type` is provided the `path` will be joined with the associated media
 * base.
 *  - `catalog/product/path/to/img.jpg`
 *
 * If a `width` is provided, "resize parameters" are added to the URL for
 * middlewares (either onboard or backend) to return using the desired width
 * and original media url.
 *  - `catalog/product/path/to/img.jpg?width=500&auto=webp&format=pjpg
 *
 * If only `path` is provided it is returned unaltered.
 *
 * @param {string} path - absolute or relative url to resource.
 * @param {Object} props - properties describing desired optimizations
 * @param {string} props.type - "image-product" or "image-category"
 * @param {number} props.width - the desired resize width of the image
 * @param {number} props.height - the desired resize height of the image
 * @param {number} props.quality - the desired quality of the image
 * @param {bool} props.crop - should the image be cropped
 * @param {string} props.fit - how should the image be fit with the dimensions: bounds, cover, crop
 */
var makeOptimizedUrl = function (path) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var {
      type
    } = _ref,
    opts = _objectWithoutProperties(_ref, _excluded);
  var {
    origin
  } = window.location;

  // optimize url if its not an image
  // DO NOT REMOVE PLEEEEEEEEEEEASEEEEEEEEEEEEEEEEEEEEE!!!!!!!!!!!
  if (!type || !type.startsWith('image-')) {
    var currentStoreCode = storeCode;
    if (!path || typeof path !== 'string' || path === '/') {
      return '/';
    }
    var newUrl = path.trim();

    //mostly production
    if (newUrl.includes(origin)) {
      //we need to add slash to match it with development condition
      newUrl = newUrl.replace(origin + '/', '/');
    }
    //mostly development
    if (newUrl.includes(MAGENTO_BACKEND_URL)) {
      //already has url in the link, so no need to add extra slash before current store code
      newUrl = newUrl.replace(MAGENTO_BACKEND_URL, '/');
    }
    var isExternal = isLinkExternal(newUrl);

    //external links shouldn't be filtered at all, taken as is
    if (!isExternal) {
      if (newUrl.charAt(0) !== "/") {
        newUrl = "/".concat(newUrl);
      }
      newUrl = newUrl.replace("/".concat(currentStoreCode, "/"), '/');
      newUrl = newUrl.replace(/\/\//g, '/');
    }
    return newUrl;
  }
  var isAbsolute = absoluteUrl.test(path);
  var magentoBackendURL = process.env.MAGENTO_BACKEND_URL;
  var baseURL = new URL(path, mediaBackend);
  // If URL is relative and has a supported type, prepend media base onto path
  if (!isAbsolute && mediaBases.has(type)) {
    var mediaBase = mediaBases.get(type);
    if (!baseURL.pathname.includes(mediaBase)) {
      baseURL = new URL(joinUrls(mediaBase, path), mediaBackend);
    }
  }
  if (baseURL.href.startsWith(magentoBackendURL) && !useBackendForImgs) {
    // Replace URL base so optimization middleware can handle request
    baseURL = new URL(baseURL.href.slice(magentoBackendURL.length), origin);
  }
  // Append image optimization parameters
  var params = new URLSearchParams(baseURL.search);
  params.set('auto', 'webp'); // Use the webp format if available
  var imageFileType = getFileType(baseURL);
  if (imageFileType === 'png') {
    params.set('format', 'png'); // use png if webp is not available
  } else {
    params.set('format', 'pjpg'); // Use progressive JPG if webp is not available
  }

  Object.entries(opts).forEach(_ref2 => {
    var [key, value] = _ref2;
    if (value !== undefined && value !== null) {
      params.set(key, value);
    }
  });
  var baseUrlHref = baseURL.href;
  baseURL.search = params.toString();
  if (baseURL.origin === origin) {
    baseUrlHref = baseUrlHref.slice(baseURL.origin.length);
  }
  baseUrlHref = baseURL.href.replace('/media/media/', '/media/');
  return baseUrlHref;
};
export default makeOptimizedUrl;