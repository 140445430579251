import makeUrl from './makeUrl';

/**
 * This is specific to the Venia store-front sample data.
 */
export var DEFAULT_WIDTH_TO_HEIGHT_RATIO = 4 / 5;
export var imageWidths = new Map(Object.entries({
  ICON: 40,
  THUMBNAIL: 80,
  SMALL: 160,
  REGULAR: 320,
  LARGE: 640,
  LARGER: 990,
  XLARGE: 1280,
  XXLARGE: 1920,
  XXXLARGE: 2500
}));
export var generateUrl = (imageURL, mediaBase) => (width, height) => makeUrl(imageURL, {
  type: mediaBase,
  width,
  height,
  fit: 'cover'
});
export var generateUrlFromContainerWidth = function (imageURL, containerWidth) {
  var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'image-product';
  var ratio = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : DEFAULT_WIDTH_TO_HEIGHT_RATIO;
  var intrinsicWidth = window.devicePixelRatio * containerWidth;

  /**
   * Find the best width that is closest to the intrinsicWidth.
   */
  var actualWidth = Array.from(imageWidths, _ref => {
    var [, value] = _ref;
    return value;
  }).reduce((prev, curr) => {
    if (prev) {
      return Math.abs(intrinsicWidth - curr) < Math.abs(intrinsicWidth - prev) ? curr : prev;
    } else {
      return curr;
    }
  }, null);
  return generateUrl(imageURL, type)(actualWidth, actualWidth / ratio);
};
export var generateSrcset = (imageURL, type, ratio) => {
  if (!imageURL || !type) return '';
  var imageRatio = ratio || DEFAULT_WIDTH_TO_HEIGHT_RATIO;
  var generateSrcsetUrl = generateUrl(imageURL, type);
  return Array.from(imageWidths, _ref2 => {
    var [, value] = _ref2;
    return value;
  }).map(width => "".concat(generateSrcsetUrl(width, Math.round(width / imageRatio)), " ").concat(width, "w")).join(',\n');
};