var _templateObject, _templateObject2, _templateObject3, _templateObject4;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import { gql } from '@apollo/client';
export var GET_CUSTOMER = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    query GetCustomerAfterSignIn {\n        customer {\n            id\n            email\n            firstname\n            lastname\n            is_subscribed\n            customer_telephone\n            dob\n            addresses {\n                id\n                city\n                country_code\n                default_billing\n                default_shipping\n                firstname\n                lastname\n                postcode\n                company\n                region {\n                    region\n                    region_code\n                    region_id\n                }\n                street\n                telephone\n            }\n        }\n    }\n"])));
export var SIGN_IN = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    mutation SignIn($email: String!, $password: String!) {\n        generateCustomerToken(email: $email, password: $password) {\n            token\n        }\n    }\n"])));
export var CREATE_CART = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    mutation CreateCartAfterSignIn {\n        cartId: createEmptyCart\n    }\n"])));
export var MERGE_CARTS = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    mutation MergeCartsAfterSignIn(\n        $sourceCartId: String!\n        $destinationCartId: String!\n    ) {\n        mergeCarts(\n            source_cart_id: $sourceCartId\n            destination_cart_id: $destinationCartId\n        ) @connection(key: \"mergeCarts\") {\n            id\n            items {\n                id\n            }\n        }\n    }\n"])));
export default {
  createCartMutation: CREATE_CART,
  getCustomerQuery: GET_CUSTOMER,
  mergeCartsMutation: MERGE_CARTS,
  signInMutation: SIGN_IN
};