export default (node => {
  var logo = node.querySelector('img[data-element="logo_image"]');
  var logoLink = node.querySelector('a[data-element="logo_link"]');
  var baseUrl = node.querySelector('[data-element="blog_base_url"]');
  var orderBy = node.querySelector('[data-element="order_by"]');
  var direction = node.querySelector('[data-element="order_direction"]');
  var items = node.querySelector('[data-element="posts_per_page"]');
  return {
    logoSrc: logo === null || logo === void 0 ? void 0 : logo.getAttribute('src'),
    logoLink: logoLink === null || logoLink === void 0 ? void 0 : logoLink.getAttribute('href'),
    orderBy: (orderBy === null || orderBy === void 0 ? void 0 : orderBy.textContent) || 'date',
    baseUrl: baseUrl === null || baseUrl === void 0 ? void 0 : baseUrl.textContent,
    direction: (direction === null || direction === void 0 ? void 0 : direction.textContent) || 'asc',
    items: (items === null || items === void 0 ? void 0 : items.textContent) || '4'
  };
});