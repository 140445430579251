var NOIBU_ENABLED = process.env.NOIBU_ENABLED === 'true' && process.env.NOIBU_DOMAIN_ID;
var verifyNoibuInitialization = () => {
  if (!NOIBU_ENABLED) {
    console.error('Noibu not enabled');
    return false;
  }
  if (!window.NOIBUJS || !window.NOIBUJS.addError) {
    console.error('Noibu not initialized');
    return false;
  }
};
export var sendError = function (error) {
  var tags = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  try {
    if (!verifyNoibuInitialization() || !error) {
      return;
    }
    if (error instanceof Error || error instanceof String) {
      var error_obj = new Error(tags.join(',') + ' ' + (error.message || error));
      window.NOIBUJS.addError(error_obj);
    } else {
      console.error("Noibu Logging => Error not in valid type");
      return;
    }
  } catch (err) {
    console.error(err);
  }
};
export var sendCustomer = customer => {
  try {
    if (!verifyNoibuInitialization() || !customer) {
      return;
    }
    var {
      customerID,
      campaign,
      isLoggedIn,
      variant
    } = customer;
    if (customerID) {
      window.NOIBUJS.addCustomAttribute('customerID', customerID);
    }
    if (campaign) {
      window.NOIBUJS.addCustomAttribute('campaign', campaign);
    }
    if (isLoggedIn) {
      window.NOIBUJS.addCustomAttribute('isLoggedIn', isLoggedIn);
    }
    if (variant) {
      window.NOIBUJS.addCustomAttribute('variant', variant);
    }
  } catch (err) {
    console.error(err);
  }
};