import { useEffect, useRef } from 'react';
import { CART_CONTENTS_KEY, PURCHASE_HISTORY_KEY, USER_VIEW_HISTORY_KEY, USER_VIEW_HISTORY_TIME_DECAY_KEY } from '../constants';
import makeUrl from '@magento/venia-ui/lib/util/makeUrl';
import useShoppingCartQuery from '@magento/venia-data-collector/lib/hooks/useShoppingCartQuery';
import mse from "@adobe/magento-storefront-events-sdk";
export default (() => {
  var firstLoad = useRef(true);
  var {
    data
  } = useShoppingCartQuery({
    fetchPolicy: 'cache-first',
    skip: !firstLoad.current
  });
  var cartEventHandler = () => {
    var shoppingCartContext = mse.context.getShoppingCart();
    var dsCart = transformData(shoppingCartContext);
    localStorage.setItem(CART_CONTENTS_KEY, JSON.stringify(dsCart));
  };
  var handleProductPageView = () => {
    var product = mse.context.getProduct();
    if (product && product !== null && product !== void 0 && product.sku) {
      var productPageViewContext = {
        date: new Date().toISOString(),
        sku: product === null || product === void 0 ? void 0 : product.sku
      };
      // if sku is not in viewHistorySkus
      // write to view_history_decay
      try {
        var viewHistory = JSON.parse(localStorage.getItem(USER_VIEW_HISTORY_TIME_DECAY_KEY));
        if (!viewHistory) {
          var updatedViewHistory = [productPageViewContext];
          localStorage.setItem(USER_VIEW_HISTORY_TIME_DECAY_KEY, JSON.stringify(updatedViewHistory));
        } else {
          var productIndex = viewHistory.findIndex(viewedProduct => (viewedProduct === null || viewedProduct === void 0 ? void 0 : viewedProduct.sku) === (product === null || product === void 0 ? void 0 : product.sku));
          if (productIndex === -1) {
            var _updatedViewHistory = [...viewHistory, productPageViewContext];
            localStorage.setItem(USER_VIEW_HISTORY_TIME_DECAY_KEY, JSON.stringify(_updatedViewHistory));
            // has been viewed before
          } else if (productIndex >= 0) {
            // remove current value in viewHistory,
            // and add the new value
            viewHistory.splice(productIndex, 1, productPageViewContext);
            localStorage.setItem(USER_VIEW_HISTORY_TIME_DECAY_KEY, JSON.stringify(viewHistory));
          }
        }
      } catch (e) {
        console.error(e);
      }

      //write to view_history
      try {
        var _viewHistory = JSON.parse(localStorage.getItem(USER_VIEW_HISTORY_KEY));
        if (!_viewHistory) {
          var _updatedViewHistory2 = {
            skus: [product === null || product === void 0 ? void 0 : product.sku]
          };
          localStorage.setItem(USER_VIEW_HISTORY_KEY, JSON.stringify(_updatedViewHistory2));
        } else {
          var _viewHistory$skus;
          var _productIndex = _viewHistory === null || _viewHistory === void 0 || (_viewHistory$skus = _viewHistory.skus) === null || _viewHistory$skus === void 0 ? void 0 : _viewHistory$skus.findIndex(viewedProduct => viewedProduct === product.sku);
          if (_productIndex === -1) {
            var _updatedViewHistory3 = {
              skus: [...(_viewHistory === null || _viewHistory === void 0 ? void 0 : _viewHistory.skus), product === null || product === void 0 ? void 0 : product.sku]
            };
            localStorage.setItem(USER_VIEW_HISTORY_KEY, JSON.stringify(_updatedViewHistory3));
            // has been viewed before
          } else if (_productIndex >= 0) {
            // remove current value in viewHistory,
            // and add the new value
            _viewHistory.skus.splice(_productIndex, 1, product === null || product === void 0 ? void 0 : product.sku);
            localStorage.setItem(USER_VIEW_HISTORY_KEY, JSON.stringify(_viewHistory));
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  };
  var handlePlaceOrder = event => {
    if (event.eventInfo && event.eventInfo.shoppingCartContext && event.eventInfo.shoppingCartContext.items) {
      var {
        items
      } = event.eventInfo.shoppingCartContext;
      items = items.map(item => {
        var _item$product;
        return item === null || item === void 0 || (_item$product = item.product) === null || _item$product === void 0 ? void 0 : _item$product.sku;
      });
      var additionalPurchaseHistory = {
        date: new Date().toISOString(),
        items
      };
      try {
        var currentPurchaseHistory = JSON.parse(localStorage.getItem(PURCHASE_HISTORY_KEY));
        var newPurchaseHistory = [...(currentPurchaseHistory ? currentPurchaseHistory : []), additionalPurchaseHistory];
        localStorage.setItem(PURCHASE_HISTORY_KEY, JSON.stringify(newPurchaseHistory));
      } catch (e) {
        console.error(e);
      }
    }
  };
  useEffect(() => {
    mse.subscribe.removeFromCart(cartEventHandler);
    mse.subscribe.addToCart(cartEventHandler);
    mse.subscribe.productPageView(handleProductPageView);
    mse.subscribe.placeOrder(handlePlaceOrder);
    return () => {
      mse.unsubscribe.removeFromCart(cartEventHandler);
      mse.unsubscribe.addToCart(cartEventHandler);
      mse.unsubscribe.productPageView(handleProductPageView);
      mse.unsubscribe.placeOrder(handlePlaceOrder);
    };
  }, []);
  useEffect(() => {
    if (data && firstLoad.current) {
      firstLoad.current = false;
      var firstLoadCart = transformData(data.cart, 'firstLoad');
      localStorage.setItem(CART_CONTENTS_KEY, JSON.stringify(firstLoadCart));
    }
  }, [data, firstLoad]);
});
var productTypesMap = new Map([['SimpleProduct', 'simple'], ['ConfigurableProduct', 'configurable']]);
var transformData = shoppingCart => {
  var dsCart;
  if (shoppingCart && shoppingCart.items && shoppingCart.items.length > 0) {
    dsCart = {
      cart: {
        items: shoppingCart.items.map(item => {
          var {
            product,
            prices
          } = item;
          return {
            product_type: productTypesMap.get(product.__typename),
            item_id: item === null || item === void 0 ? void 0 : item.id,
            qty: item === null || item === void 0 ? void 0 : item.quantity,
            product_id: product === null || product === void 0 ? void 0 : product.id,
            product_name: product === null || product === void 0 ? void 0 : product.name,
            product_sku: product === null || product === void 0 ? void 0 : product.sku,
            product_url: makeUrl("".concat(window.location.origin, "/").concat(product === null || product === void 0 ? void 0 : product.url_key).concat(product === null || product === void 0 ? void 0 : product.url_suffix)),
            product_price_value: prices.price.value,
            product_image: {
              src: product === null || product === void 0 ? void 0 : product.thumbnail.url,
              alt: (product === null || product === void 0 ? void 0 : product.thumbnail.label) || ''
            }
          };
        })
      }
    };
  } else {
    dsCart = {
      cart: {
        items: []
      }
    };
  }
  return dsCart;
};