export default ((node, props) => {
  var hash = node.childNodes[0].innerText;
  var title = node.childNodes[1].querySelector('p').outerHTML;
  ;
  var linkText = node.childNodes[2].innerText;
  var href = node.childNodes[3].getAttribute('href');
  return {
    hash,
    title,
    linkText,
    href
  };
});