export default (args => {
  var {
    src = "",
    async = false,
    type = "application/javascript",
    element = document.head,
    body
  } = args;
  return new Promise((resolve, reject) => {
    var script = document.createElement('script');
    script.src = src;
    script.async = async;
    script.type = type;
    if (body) {
      script.append(body instanceof Object ? JSON.stringify(body) : body);
    }
    script.addEventListener('load', resolve);
    script.addEventListener('error', e => reject(e.error));
    element.appendChild(script);
  });
});