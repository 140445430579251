function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { handleActions } from 'redux-actions';
import actions from '../actions/app';
import { BrowserPersistence } from '@magento/peregrine/lib/util';
var storage = new BrowserPersistence();
export var name = 'app';
var initialState = {
  drawer: null,
  hasBeenOffline: !navigator.onLine,
  isOnline: navigator.onLine,
  isPageLoading: false,
  overlay: false,
  pending: {},
  searchOpen: false,
  ipLat: 0,
  ipLng: 0,
  openStoreModal: false,
  currentStore: null,
  openSearchBar: false,
  searchBarValue: null,
  badges: null,
  storeConfig: null,
  preferenceCookiesEnabled: storage.getItem('cookies-preferences-allowed')
};
var reducerMap = {
  [actions.setStoreConfig]: (state, _ref) => {
    var {
      payload
    } = _ref;
    return _objectSpread(_objectSpread({}, state), {}, {
      storeConfig: payload
    });
  },
  [actions.setProductBadges]: (state, _ref2) => {
    var {
      payload
    } = _ref2;
    return _objectSpread(_objectSpread({}, state), {}, {
      badges: payload
    });
  },
  [actions.setPreferenceCookies]: (state, _ref3) => {
    var {
      payload
    } = _ref3;
    storage.setItem('cookies-preferences-allowed', payload);
    storage.setItem('cookies-bar-shown', true);
    return _objectSpread(_objectSpread({}, state), {}, {
      preferenceCookiesEnabled: payload
    });
  },
  [actions.toggleDrawer]: (state, _ref4) => {
    var {
      payload
    } = _ref4;
    return _objectSpread(_objectSpread({}, state), {}, {
      drawer: payload,
      overlay: !!payload
    });
  },
  [actions.toggleSearch]: state => {
    return _objectSpread(_objectSpread({}, state), {}, {
      searchOpen: !state.searchOpen
    });
  },
  [actions.setOnline]: state => {
    return _objectSpread(_objectSpread({}, state), {}, {
      isOnline: true
    });
  },
  [actions.setOffline]: state => {
    return _objectSpread(_objectSpread({}, state), {}, {
      isOnline: false,
      hasBeenOffline: true
    });
  },
  [actions.setPageLoading]: (state, _ref5) => {
    var {
      payload
    } = _ref5;
    return _objectSpread(_objectSpread({}, state), {}, {
      isPageLoading: !!payload
    });
  },
  [actions.setIpLatLng]: (state, _ref6) => {
    var {
      payload
    } = _ref6;
    return _objectSpread(_objectSpread({}, state), payload);
  },
  [actions.setOpenStoreModal]: (state, _ref7) => {
    var {
      payload
    } = _ref7;
    return _objectSpread(_objectSpread({}, state), {}, {
      openStoreModal: payload
    });
  },
  [actions.setCurrentStore]: (state, _ref8) => {
    var {
      payload
    } = _ref8;
    return _objectSpread(_objectSpread({}, state), {}, {
      currentStore: payload
    });
  },
  [actions.setOpenSearchBar]: (state, _ref9) => {
    var {
      payload
    } = _ref9;
    return _objectSpread(_objectSpread({}, state), {}, {
      openSearchBar: payload
    });
  },
  [actions.setSearchBarValue]: (state, _ref10) => {
    var {
      payload
    } = _ref10;
    return _objectSpread(_objectSpread({}, state), {}, {
      searchBarValue: payload
    });
  }
};
export default handleActions(reducerMap, initialState);