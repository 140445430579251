;window.__fetchLocaleData__ = (function () {
            return async function getLocale(locale) {
                if (locale === "en_CA") {
                        return import(/* webpackChunkName: "i18n-en_CA" */'/opt/atlassian/pipelines/agent/build/app/pwa/packages/venia-concept/en_CA.json');
                    }if (locale === "fr_CA") {
                        return import(/* webpackChunkName: "i18n-fr_CA" */'/opt/atlassian/pipelines/agent/build/app/pwa/packages/venia-concept/fr_CA.json');
                    }

                throw new Error('Unable to locate locale ' + locale + ' within generated dist directory.');
            }
        })()