import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useAppContext } from '@magento/peregrine/lib/context/app';
import { STORE_MENU_DATA } from '@magento/venia-ui/lib/components/Header/headerNavigation.gql';
import { useStoreSwitcher } from './useStoreSwitcher';
import parseMenuNodes from './parseMenuNodes';
import { useLocation } from 'react-router-dom';
export var useHeader = _ref => {
  var {
    isMobile
  } = _ref;
  var location = useLocation();
  var {
    currentStoreCode
  } = useStoreSwitcher();
  var [{
    hasBeenOffline,
    isOnline,
    isPageLoading
  }] = useAppContext();
  var [loadMenuHeader, {
    data: menuData,
    error: menuError
  }] = useLazyQuery(STORE_MENU_DATA, {
    fetchPolicy: 'cache-and-network',
    variables: {
      identifier: isMobile ? 'mobile_header_menu' : 'header_menu',
      activeStoreCode: currentStoreCode
    }
  });

  //we need to pass it separately, because of concurrency
  var getFormattedHeaderData = useCallback(() => {
    if (menuData && menuData.storeMenuByIdentifier && menuData.storeMenuByIdentifier.items) {
      var menuNodes = [];
      if (menuData.storeMenuByIdentifier.items.length > 0) {
        menuNodes = parseMenuNodes(menuData.storeMenuByIdentifier.items[0].nodes);
        var headerMenu = [];
        if (menuNodes) {
          menuNodes.map(node => {
            if (node.classes === "ignore-parent-node") {
              if (node.left && node.left.length) {
                node.left.forEach(val => {
                  headerMenu.push(val);
                });
              }
            } else {
              headerMenu.push(node);
            }
          });
        }
        menuNodes = headerMenu;
      }
      return menuNodes;
    } else if (menuError) {
      console.error(menuError);
    }
    return null;
  }, [menuData, menuError, isMobile, location]);
  return {
    menuData,
    loadMenuHeader,
    hasBeenOffline,
    isOnline,
    isPageLoading,
    getFormattedHeaderData,
    location
  };
};