import { createActions } from 'redux-actions';
var prefix = 'CATALOG';
var actionTypes = ['UPDATE_CATEGORIES'];
var actionMap = {
  SET_CURRENT_PAGE: {
    REQUEST: null,
    RECEIVE: null
  },
  SET_PREV_PAGE_TOTAL: {
    REQUEST: null,
    RECEIVE: null
  }
};
export default createActions(actionMap, ...actionTypes, {
  prefix
});