var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import { gql } from '@apollo/client';
import { CartTriggerFragment } from '../Header/cartTriggerFragments.gql';
import { MiniCartFragment } from '../MiniCart/miniCartFragments.gql';
export var ADD_PRODUCT_TO_CART = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    mutation AddProductToCart($cartId: String!, $product: CartItemInput!) {\n        addProductsToCart(cartId: $cartId, cartItems: [$product]) {\n            cart {\n                id\n                ...CartTriggerFragment\n                ...MiniCartFragment\n            }\n            user_errors {\n                code\n                message\n            }\n        }\n    }\n    ", "\n    ", "\n"])), CartTriggerFragment, MiniCartFragment);
export var GET_WISHLIST_CONFIG = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n     query wishlistConfig  {\n        isWishlistEnabled(website_id:1) {\n            enabled\n        }\n    }\n"])));

/**
 * @deprecated - replaced by general mutation in @magento/peregrine/lib/talons/productFullDetail.js
 */
export var ADD_CONFIGURABLE_MUTATION = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    mutation addConfigurableProductToCart(\n        $cartId: String!\n        $quantity: Float!\n        $sku: String!\n        $parentSku: String!\n    ) {\n        addConfigurableProductsToCart(\n            input: {\n                cart_id: $cartId\n                cart_items: [\n                    {\n                        data: { quantity: $quantity, sku: $sku }\n                        parent_sku: $parentSku\n                    }\n                ]\n            }\n        ) @connection(key: \"addConfigurableProductsToCart\") {\n            cart {\n                id\n                # Update the cart trigger when adding an item.\n                ...CartTriggerFragment\n                # Update the mini cart when adding an item.\n                ...MiniCartFragment\n            }\n        }\n    }\n    ", "\n    ", "\n"])), CartTriggerFragment, MiniCartFragment);

/**
 * @deprecated - replaced by general mutation in @magento/peregrine/lib/talons/productFullDetail.js
 */
export var ADD_SIMPLE_MUTATION = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    mutation addSimpleProductToCart(\n        $cartId: String!\n        $quantity: Float!\n        $sku: String!\n    ) {\n        addSimpleProductsToCart(\n            input: {\n                cart_id: $cartId\n                cart_items: [{ data: { quantity: $quantity, sku: $sku } }]\n            }\n        ) @connection(key: \"addSimpleProductsToCart\") {\n            cart {\n                id\n                # Update the cart trigger when adding an item.\n                ...CartTriggerFragment\n                # Update the mini cart when adding an item.\n                ...MiniCartFragment\n            }\n        }\n    }\n    ", "\n    ", "\n"])), CartTriggerFragment, MiniCartFragment);
export var ADD_GIFT_MUTATION = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    mutation addGiftCardToCart(\n        $message: String!\n        $cartId: String!\n        $selectedOptions: ID!\n        $senderNameUid: ID!\n        $senderName: String!\n        $recipientNameUid: ID!\n        $recipientName: String!\n        $senderEmailUid: ID!\n        $senderEmail: String!\n        $recipientEmailUid: ID!\n        $recipientEmail: String!\n        $messageUid: ID!\n        $dateUid: ID!\n        $quantity: Float!\n        $sku: String!\n        $customAmountUid: ID!\n        $customAmount: String!\n        $date: String!\n    ) {\n        addProductsToCart(\n            cartId: $cartId\n            cartItems: [\n                {\n                    quantity: $quantity\n                    sku: $sku\n                    selected_options: [$selectedOptions]\n                    entered_options: [\n                        { uid: $senderNameUid, value: $senderName }\n                        { uid: $recipientNameUid, value: $recipientName }\n                        { uid: $senderEmailUid, value: $senderEmail }\n                        { uid: $recipientEmailUid, value: $recipientEmail }\n                        { uid: $messageUid, value: $message }\n                        { uid: $customAmountUid, value: $customAmount }\n                        { uid: $dateUid, value: $date }\n                    ]\n                }\n            ]\n        ) @connection(key: \"addGiftCardToCart\") {\n            cart {\n                id\n                # Update the cart trigger when adding an item.\n                ...CartTriggerFragment\n                # Update the mini cart when adding an item.\n                ...MiniCartFragment\n            }\n            user_errors {\n                code\n                message\n            }\n        }\n    }\n    ", "\n    ", "\n"])), CartTriggerFragment, MiniCartFragment);
export default {
  addGiftCardMutation: ADD_GIFT_MUTATION,
  addConfigurableProductToCartMutation: ADD_CONFIGURABLE_MUTATION,
  addProductToCartMutation: ADD_PRODUCT_TO_CART,
  addSimpleProductToCartMutation: ADD_SIMPLE_MUTATION,
  getWishlistConfigQuery: GET_WISHLIST_CONFIG
};