function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import md5 from 'md5';
import { getStoreCode } from "../components/StoreCodeRoute/storeCodeRoute";
import { sha256 } from 'js-sha256';
import { BrowserPersistence } from '@magento/peregrine/lib/util';
var storage = new BrowserPersistence();
var currency = storage.getItem("store_view_currency") || "CAD";
var activeStoreCode = getStoreCode();
var timer2 = 0;
var timer4 = 0;
var timer5 = 0;
var timer6 = 0;
var getCartForHelpers = cartResp => {
  return cartResp && (cartResp.cart || cartResp.customerCart || cartResp);
};
export var getWindowLocation = includeParams => {
  var url = document.location.protocol + '//' + document.location.hostname + document.location.pathname;
  if (includeParams) {
    url += document.location.search;
  }
  return url;
};
var formatPhoneNumber = function () {
  var phone = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var filtered = phone.replace(/\D+/g, '');
  if (filtered.startsWith('+')) {
    return filtered;
  }
  if (filtered.length > 10) {
    return "+".concat(filtered);
  }
  return "+1".concat(filtered);
};
var normalizeValue = function () {
  var val = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  return val && val.trim().toLowerCase();
};
var normalizeEncryptValue = function () {
  var val = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  return val && sha256(normalizeValue(val));
};
export var saveEncryptedEmailPersistence = function () {
  var val = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return val && storage.setItem('sha_256_email', normalizeEncryptValue(val));
};
var getHashedEmail = () => {
  return storage.getItem('sha_256_email');
};
var getHashedEmailGtm = () => {
  var sha256_email_address = getHashedEmail();
  return sha256_email_address ? {
    user_data: {
      sha256_email_address
    }
  } : {};
};

// Order Confirmation Shipping and Billing addresses are sent separately
// Because OC cleans all storage values, therefore those won't be accessed
export var getHashedUserData = (ocShippingAddress, ocBillingAddress) => {
  try {
    var _signed_payload$regio, _signed_payload$regio2;
    var signed_user_data = storage.getItem('currentUserData');
    var signed_payload = signed_user_data === null || signed_user_data === void 0 ? void 0 : signed_user_data.payload;
    var signed_payload_addresses = (signed_payload === null || signed_payload === void 0 ? void 0 : signed_payload.addresses) || [];
    var signed_payload_billing_address = (signed_payload_addresses === null || signed_payload_addresses === void 0 ? void 0 : signed_payload_addresses.find(el => el.default_billing)) || signed_payload_addresses[0];
    var signed_payload_shipping_address = (signed_payload_addresses === null || signed_payload_addresses === void 0 ? void 0 : signed_payload_addresses.find(el => el.default_shipping)) || signed_payload_addresses[0];
    var checkout_billing_address = ocBillingAddress || storage.getItem('billingAddress');
    var checkout_shipping_address = ocShippingAddress || storage.getItem('shippingAddress');
    var email = signed_payload === null || signed_payload === void 0 ? void 0 : signed_payload.email;
    var phone = (signed_payload === null || signed_payload === void 0 ? void 0 : signed_payload.customer_telephone) || (checkout_billing_address === null || checkout_billing_address === void 0 ? void 0 : checkout_billing_address.telephone) || (checkout_shipping_address === null || checkout_shipping_address === void 0 ? void 0 : checkout_shipping_address.telephone);
    var firstName = (signed_payload === null || signed_payload === void 0 ? void 0 : signed_payload.firstname) || (checkout_billing_address === null || checkout_billing_address === void 0 ? void 0 : checkout_billing_address.firstname) || (checkout_shipping_address === null || checkout_shipping_address === void 0 ? void 0 : checkout_shipping_address.firstname);
    var lastName = (signed_payload === null || signed_payload === void 0 ? void 0 : signed_payload.lastname) || (checkout_billing_address === null || checkout_billing_address === void 0 ? void 0 : checkout_billing_address.lastname) || (checkout_shipping_address === null || checkout_shipping_address === void 0 ? void 0 : checkout_shipping_address.lastname);

    //no encryption
    var street = (signed_payload_billing_address === null || signed_payload_billing_address === void 0 ? void 0 : signed_payload_billing_address.street.join(', app: ')) || (signed_payload_shipping_address === null || signed_payload_shipping_address === void 0 ? void 0 : signed_payload_shipping_address.street.join(', app: ')) || (checkout_billing_address === null || checkout_billing_address === void 0 ? void 0 : checkout_billing_address.street.join(', app: ')) || (checkout_shipping_address === null || checkout_shipping_address === void 0 ? void 0 : checkout_shipping_address.street.join(', app: ')) || (checkout_billing_address === null || checkout_billing_address === void 0 ? void 0 : checkout_billing_address.street1) && "".concat(checkout_billing_address.street1).concat(checkout_billing_address.street2 ? ', app: ' + checkout_billing_address.street2 : '') || (checkout_shipping_address === null || checkout_shipping_address === void 0 ? void 0 : checkout_shipping_address.street1) && "".concat(checkout_shipping_address.street1).concat(checkout_shipping_address.street2 ? ', app: ' + checkout_shipping_address.street2 : '');
    var city = (signed_payload === null || signed_payload === void 0 ? void 0 : signed_payload.city) || (checkout_billing_address === null || checkout_billing_address === void 0 ? void 0 : checkout_billing_address.city) || (checkout_shipping_address === null || checkout_shipping_address === void 0 ? void 0 : checkout_shipping_address.city);
    var region = (signed_payload === null || signed_payload === void 0 || (_signed_payload$regio = signed_payload.region) === null || _signed_payload$regio === void 0 ? void 0 : _signed_payload$regio.region_code) || (signed_payload === null || signed_payload === void 0 || (_signed_payload$regio2 = signed_payload.region) === null || _signed_payload$regio2 === void 0 ? void 0 : _signed_payload$regio2.region) || (checkout_billing_address === null || checkout_billing_address === void 0 ? void 0 : checkout_billing_address.region_code) || (checkout_shipping_address === null || checkout_shipping_address === void 0 ? void 0 : checkout_shipping_address.region_code);
    if (!region) {
      //JSON parse might throw error, so just catching it if possible
      try {
        var _JSON$parse, _JSON$parse2;
        region = (checkout_billing_address === null || checkout_billing_address === void 0 ? void 0 : checkout_billing_address.region) && ((_JSON$parse = JSON.parse(checkout_billing_address === null || checkout_billing_address === void 0 ? void 0 : checkout_billing_address.region)) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.label) || (checkout_shipping_address === null || checkout_shipping_address === void 0 ? void 0 : checkout_shipping_address.region) && ((_JSON$parse2 = JSON.parse(checkout_shipping_address === null || checkout_shipping_address === void 0 ? void 0 : checkout_shipping_address.region)) === null || _JSON$parse2 === void 0 ? void 0 : _JSON$parse2.label);
      } catch (e) {
        console.error('User GTM parse region failure:', e);
        region = (checkout_billing_address === null || checkout_billing_address === void 0 ? void 0 : checkout_billing_address.region) || (checkout_shipping_address === null || checkout_shipping_address === void 0 ? void 0 : checkout_shipping_address.region);
      }
    }
    var postal_code = (signed_payload === null || signed_payload === void 0 ? void 0 : signed_payload.postcode) || (checkout_billing_address === null || checkout_billing_address === void 0 ? void 0 : checkout_billing_address.postcode) || (checkout_shipping_address === null || checkout_shipping_address === void 0 ? void 0 : checkout_shipping_address.postcode);
    var country = 'CA';

    //sha256 encryption
    var sha256_email_address = storage.getItem('sha_256_email') || email && normalizeEncryptValue(email);
    var sha256_phone_number = phone && sha256(formatPhoneNumber(phone));
    var sha256_first_name = firstName && normalizeEncryptValue(firstName);
    var sha256_last_name = lastName && normalizeEncryptValue(lastName);
    return {
      user_data: {
        sha256_email_address,
        sha256_phone_number,
        address: {
          sha256_first_name,
          sha256_last_name,
          street: normalizeValue(street),
          city: normalizeValue(city),
          region: normalizeValue(region),
          country: normalizeValue(country),
          postal_code: normalizeValue(postal_code)
        }
      }
    };
  } catch (e) {
    console.error('User GTM parsing error', e);
  }
  return {};
};
export var sendGTMContainer = function () {
  var extraProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  try {
    var currentUser = storage.getItem("currentUserData");
    var currentUserEmail = currentUser && currentUser.payload && currentUser.payload.email;
    var encryptedEmail = currentUserEmail && md5(currentUserEmail);
    var currentPath = storage.getItem("currentPath");
    var _page = 'page_view';
    if (currentPath !== document.location.pathname) {
      storage.setItem("currentPath", document.location.pathname);
    } else {
      _page = 'virtual_page_view';
    }
    var tagManagerArgs = _objectSpread(_objectSpread({
      event: _page,
      originalLocation: storage.getItem("landing_initial_page_url"),
      location: getWindowLocation(true),
      language: activeStoreCode,
      loginStatus: currentUserEmail ? "logged" : "disconnected",
      userId: encryptedEmail
    }, getHashedEmailGtm()), extraProps);

    //if need to reset last event
    //window.dataLayer.push({ecommerce: null});
    //timeout for making sur that datalayer is there
    setTimeout(() => {
      window.dataLayer.push(tagManagerArgs);
    }, 20);
  } catch (e) {
    console.error(e);
  }
};
export var sendGTMEvent = function () {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var tagManagerArgs = _objectSpread(_objectSpread({}, props), getHashedEmailGtm());

  //if need to reset last event
  //window.dataLayer.push({ecommerce: null});
  window.dataLayer.push(tagManagerArgs);
};
export var formatProductsGTM = products => {
  if (!products) {
    return {};
  }
  var productsObj = {
    prodSku: [],
    prodName: [],
    prodCat: [],
    prodPr: [],
    prodQty: []
  };
  if (!products.length) {
    return productsObj;
  }
  products.forEach(item => {
    var prod = item.product || item;
    var qty = item.quantity || item.qty_ordered || 1;
    var price = prod.price_range && prod.price_range.maximum_price.final_price.value || prod.price;
    productsObj.prodSku.push(prod.sku);
    productsObj.prodName.push(prod.name);
    productsObj.prodPr.push(price);
    productsObj.prodQty.push(qty);
    if (prod.categories) {
      //productsObj.prodCat.push(prod.categories.map(cat => cat.name).join('/').slice(4) || "");
      prod.categories.map(cat => {
        if (cat.breadcrumbs) {
          productsObj.prodCat.push(cat.breadcrumbs.slice(0, 4).map(item => item.category_name).join('/'));
        } else {
          productsObj.prodCat.push(cat.name);
        }
      });
    } else if (prod.extension_attributes) {
      var _categoryData = [];
      Object.entries(prod.extension_attributes).forEach(_ref => {
        var [key, value] = _ref;
        if (key.includes('category')) {
          _categoryData.push(value);
        }
      });
      productsObj.prodCat.push(_categoryData.slice(0, 4).join('/') || "");
    }
  });
  return productsObj;
};
export var getFreeShippingStatus = total => {
  return parseFloat(total) > storage.getItem('freeShippingValue');
};
export var getGrandTotalFromCart = cartResp => {
  var cart = getCartForHelpers(cartResp);
  return cart && cart.prices.grand_total.value;
};
export var getProdTotalFromCart = cartResp => {
  var cart = getCartForHelpers(cartResp);
  return cart && cart.items.reduce((prev, current) => {
    return prev + current.product.price_range.maximum_price.final_price.value * current.quantity;
  }, 0);
};
export var getSavedTotalFromCart = cartResp => {
  var cart = getCartForHelpers(cartResp);
  var cartDiscounts = cart && cart.prices.discounts && cart.prices.discounts.reduce((prev, curr) => prev + curr.amount.value) || 0;
  var itemDiscounts = cart && cart.items.reduce((prev, current) => {
    return prev + current.product.price_range.maximum_price.discount.amount_off * current.quantity;
  }, 0);
  return cartDiscounts + itemDiscounts;
};
export var getShippingTotalFromCart = cartResp => {
  var cart = getCartForHelpers(cartResp);
  return cart && cart.prices.shipping_addresses && cart.prices.shipping_addresses[0].selected_shipping_method.amount.value;
};
export var getCouponCodesFromCart = cartResp => {
  var cart = getCartForHelpers(cartResp);
  return cart && cart.applied_coupons && cart.applied_coupons.map(coup => coup.code).join(",") || "";
};
export var categoryGtm = dataLayer => {
  var currentUrl = window.location.pathname + window.location.search;
  var data = dataLayer.onClickValue ? {
    'ecommerce': {
      'currencyCode': currency,
      'click': {
        'actionField': {
          'list': dataLayer.onClickValue
        },
        'products': []
      }
    }
  } : {
    'page': {
      'language': activeStoreCode
    },
    'ecommerce': {
      'currencyCode': currency,
      'impressions': []
    }
  };
  if (dataLayer.onClickValue) {
    storage.setItem("last_category_or_search", dataLayer.onClickValue);
  }
  if (dataLayer.index) storage.setItem("last_position", dataLayer.index);
  if (dataLayer.data && dataLayer.data.length) {
    dataLayer.data.map((item, index) => {
      var _categories = '';
      if (dataLayer.categories || item.categories && item.categories !== 'undefined') {
        _categories = dataLayer.categories ? dataLayer.categories.slice(0, 5).join('/') : item.categories.slice(0, 5).join('/');
      }
      var colorVariant = null;
      if (item.variants && item.variants.length) {
        var min_ = item.variants[0].product.price.minimalPrice.amount.value;
        var variant_min_ = item.variants[0];
        item.variants.map(variant => {
          if (variant.product.price.minimalPrice.amount.value < min_) {
            min_ = variant.product.price.minimalPrice.amount.value;
            variant_min_ = variant;
          }
        });
        var firstColor = variant_min_.attributes.filter(atr_ => atr_.code === "color")[0];
        if (firstColor) colorVariant = firstColor.label;
      } else if (dataLayer.attributes) {
        var _firstColor = dataLayer.attributes.filter(atr_ => atr_.code === "color")[0];
        if (_firstColor) colorVariant = _firstColor.label;
      }
      if (dataLayer.onClickValue) {
        data.ecommerce.click.products.push(colorVariant ? {
          'position': dataLayer.index ? dataLayer.index : index,
          'id': item.sku,
          'name': item.name,
          'currency': item.storeBaseCurrency,
          'price': item.salePrice,
          'brand': item.brand,
          'variant': colorVariant,
          'category': _categories
        } : {
          'position': dataLayer.index ? dataLayer.index : index,
          'id': item.sku,
          'name': item.name,
          'currency': item.storeBaseCurrency,
          'price': item.salePrice,
          'brand': item.brand,
          'category': _categories
        });
      } else {
        var brand = item.marque && dataLayer.marques.find(marque => marque.value === String(item.marque));
        data.ecommerce.impressions.push(colorVariant ? {
          'position': dataLayer.index ? dataLayer.index : index,
          'id': item.sku,
          'name': item.name,
          'currency': item.storeBaseCurrency,
          'price': item.salePrice,
          'brand': brand && brand.label || brand,
          //search page return the actual brand, instead of id
          'variant': colorVariant,
          'category': _categories
        } : {
          'position': dataLayer.index ? dataLayer.index : index,
          'id': item.sku,
          'name': item.name,
          'currency': item.storeBaseCurrency,
          'price': item.salePrice,
          'brand': brand && brand.label || brand,
          //search page return the actual brand, instead of id
          'category': _categories
        });
      }
    });
    storage.setItem("last_category_or_search_products", dataLayer.onClickValue ? data.ecommerce.click.products : data.ecommerce.impressions);
    if (process.env.GOOGLE_GTM_KEY) {
      var tagManagerArgs = _objectSpread(_objectSpread({
        "event": dataLayer.event ? dataLayer.event : process.env.GOOGLE_DEFAULT_EVENT
      }, getHashedEmailGtm()), data);
      if (timer2) {
        clearTimeout(timer2);
      }
      timer2 = setTimeout(() => {
        storage.setItem("last_url", currentUrl);
        window.dataLayer.push({
          ecommerce: null
        });
        return window.dataLayer.push(tagManagerArgs);
      }, 50);
    }
  }
  return null;
};
export var pdpGtm = dataLayer => {
  var data = {
    'page': {
      'language': activeStoreCode
    },
    'ecommerce': {
      'currencyCode': currency,
      'detail': {
        'products': [dataLayer.item]
      }
    }
  };
  if (process.env.GOOGLE_GTM_KEY) {
    var tagManagerArgs = _objectSpread(_objectSpread({
      "event": process.env.GOOGLE_DEFAULT_EVENT
    }, getHashedEmailGtm()), data);
    if (timer4) {
      clearTimeout(timer4);
    }
    timer4 = setTimeout(() => {
      window.dataLayer.push({
        ecommerce: null
      });
      return window.dataLayer.push(tagManagerArgs);
    }, 1000);
  }
  return null;
};
export var addToCartGtm = dataLayer => {
  var data = {
    "currencyCode": currency,
    "add": {
      "actionField": {
        "action": "add"
      },
      "products": [dataLayer.item]
    }
  };
  if (process.env.GOOGLE_GTM_KEY) {
    var tagManagerArgs = _objectSpread(_objectSpread({
      "event": "ecommerceEvent"
    }, getHashedEmailGtm()), {}, {
      "ecommerce": data
    });
    if (timer5) {
      clearTimeout(timer5);
    }
    timer5 = setTimeout(() => {
      window.dataLayer.push({
        ecommerce: null
      });
      return window.dataLayer.push(tagManagerArgs);
    }, 50);
  }
  return null;
};
export var removeFromCartGtm = dataLayer => {
  var data = {
    "currencyCode": currency,
    "remove": {
      "actionField": {
        "action": "remove"
      },
      "products": [dataLayer.item]
    }
  };
  if (process.env.GOOGLE_GTM_KEY) {
    var tagManagerArgs = _objectSpread(_objectSpread({
      event: "ecommerceEvent"
    }, getHashedEmailGtm()), {}, {
      "ecommerce": data
    });
    if (timer6) {
      clearTimeout(timer6);
    }
    timer6 = setTimeout(() => {
      window.dataLayer.push({
        ecommerce: null
      });
      return window.dataLayer.push(tagManagerArgs);
    }, 50);
  }
  return null;
};