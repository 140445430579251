/**
 * The talon for working with PlaceholderImages.
 * Determines whether the visual placeholder should be rendered or not.
 *
 * @param {bool}     props.displayPlaceholder whether or not to display a visual placeholder.
 * @param {string}   props.imageHasError there was an error loading the actual image.
 * @param {string}   props.imageIsLoaded the actual image is loaded.
 */
export var usePlaceholderImage = props => {
  var {
    displayPlaceholder,
    imageHasError,
    imageIsLoaded
  } = props;

  // Render the placeholder unless we've been told not to,
  // or we've already loaded without error.
  var hidePlaceholder = !displayPlaceholder || imageIsLoaded && !imageHasError;
  var shouldRenderPlaceholder = !hidePlaceholder;
  return {
    shouldRenderPlaceholder
  };
};