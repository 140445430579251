function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getMargin, getBackgroundImages, getBorder, getPadding, getTextAlign, getCssClasses, getIsHidden } from '../../utils';

/**
 * Determine the button type based on class
 *
 * @param node
 * @returns {string}
 */
var getButtonType = node => {
  if (node.classList.contains('pagebuilder-button-secondary')) {
    return 'secondary';
  }
  if (node.classList.contains('pagebuilder-button-link')) {
    return 'link';
  }
  return 'primary';
};
export default ((node, props) => {
  var wrapperElement = node.querySelector('[data-element="wrapper"]');
  var overlayElement = node.querySelector('[data-element="overlay"]');
  var linkElement = node.querySelector('a[data-element="link"]');
  var buttonElement = node.querySelector('[data-element="button"]');
  var videoOverlayElement = node.querySelector('[data-element="video_overlay"]');
  var showButton = node.getAttribute('data-show-button');
  var showOverlay = node.getAttribute('data-show-overlay');
  var minHeightPaddingElement = wrapperElement;
  if (props.appearance === 'poster') {
    minHeightPaddingElement = overlayElement;
  }
  return _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({
    minHeight: minHeightPaddingElement.style.minHeight || null,
    backgroundColor: wrapperElement.style.backgroundColor
  }, getBackgroundImages(wrapperElement)), {}, {
    content: node.querySelector('[data-element="content"]').innerHTML,
    link: linkElement ? linkElement.getAttribute('href') : null,
    linkType: linkElement ? linkElement.getAttribute('data-link-type') : null,
    openInNewTab: linkElement && linkElement.getAttribute('target') === '_blank',
    showButton,
    buttonText: buttonElement && showButton !== 'never' ? buttonElement.textContent : null,
    buttonType: buttonElement && showButton !== 'never' ? getButtonType(buttonElement) : null,
    showOverlay,
    overlayColor: overlayElement && showOverlay !== 'never' ? overlayElement.getAttribute('data-overlay-color') : null,
    backgroundType: wrapperElement.getAttribute('data-background-type'),
    videoSrc: wrapperElement.getAttribute('data-video-src'),
    videoFallbackSrc: wrapperElement.getAttribute('data-video-fallback-src'),
    videoLoop: wrapperElement.getAttribute('data-video-loop') === 'true',
    videoPlayOnlyVisible: wrapperElement.getAttribute('data-video-play-only-visible') === 'true',
    videoLazyLoading: wrapperElement.getAttribute('data-video-lazy-load') === 'true',
    videoOverlayColor: videoOverlayElement ? videoOverlayElement.getAttribute('data-video-overlay-color') : null
  }, getTextAlign(wrapperElement)), getBorder(wrapperElement)), getCssClasses(node)), getMargin(node)), getPadding(minHeightPaddingElement)), getIsHidden(node));
});