import { createActions } from 'redux-actions';
var prefix = 'USER';
var actionTypes = ['RESET', 'SET_TOKEN', 'CLEAR_TOKEN'];
var actionMap = {
  SIGN_IN: {
    REQUEST: null,
    RECEIVE: null
  },
  GET_DETAILS: {
    REQUEST: null,
    RECEIVE: null
  },
  CREATE_ACCOUNT: {
    REQUEST: null,
    RECEIVE: null
  },
  RESET_PASSWORD: {
    REQUEST: null,
    RECEIVE: null
  },
  SHARE_TO_WISHLIST: {
    REQUEST: null,
    RECEIVE: null
  },
  WISHLIST_SHARE: {
    REQUEST: null,
    RECEIVE: null
  }
};
export default createActions(actionMap, ...actionTypes, {
  prefix
});