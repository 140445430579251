var _templateObject, _templateObject2, _templateObject3;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import { gql } from '@apollo/client';
export var GET_STORE_CONFIG_DATA = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    query getStoreConfigData {\n        storeConfig {\n            id\n            code\n            store_name\n            store_group_name\n        }\n    }\n"])));
export var GET_URL_RESOLVER_DATA = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    query getUrlResolverData($url: String!, $storeFrom: String) {\n        urlResolver(url: $url, storeFrom: $storeFrom) {\n            id\n            relative_url\n            type\n        }\n    }\n"])));
export var GET_AVAILABLE_STORES_DATA = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    query getAvailableStoresData {\n        availableStores {\n            category_url_suffix\n            code\n            default_display_currency_code\n            id\n            locale\n            product_url_suffix\n            secure_base_media_url\n            store_group_code\n            store_group_name\n            store_name\n            store_sort_order\n        }\n    }\n"])));
export default {
  getStoreConfigData: GET_STORE_CONFIG_DATA,
  getUrlResolverData: GET_URL_RESOLVER_DATA,
  getAvailableStoresData: GET_AVAILABLE_STORES_DATA
};