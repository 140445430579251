export default (node => {
  var subCategories = JSON.parse(node.getAttribute('data-subcategories'));
  var linkTitle = node.getAttribute('data-link-title');
  var title = node.querySelector('div[data-cms-title="Title"]').querySelector('p').outerHTML;
  var linkHref = node.querySelector('a[data-element="link"]');
  return {
    linkTitle: linkTitle ? linkTitle : null,
    linkHref: linkHref ? linkHref.getAttribute('href') : null,
    subCategories: subCategories.subcategories,
    title
  };
});