function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { handleActions } from 'redux-actions';
import actions from '../actions/catalog';
export var name = 'catalog';
var fromPairs = pairs => {
  var result = {};
  for (var [key, value] of pairs) {
    result[key] = value;
  }
  return result;
};
var initialState = {
  categories: {},
  currentPage: 1,
  pageSize: 6,
  prevPageTotal: null
};
var reducerMap = {
  [actions.updateCategories]: (state, _ref) => {
    var {
      payload
    } = _ref;
    var {
      id
    } = payload;
    var currentCategory = state.categories[id] || {};

    // if category has already been fetched, do nothing
    if (currentCategory.children) {
      return state;
    }

    // sort children by `position`
    var children = [...payload.children].sort((a, b) => {
      if (a.position > b.position) {
        return 1;
      } else if (a.position === b.position && a.id > b.id) {
        return 1;
      } else {
        return -1;
      }
    });

    // use a Map to preserve sort order
    // since a plain object with numeric keys would lose it
    var childMap = new Map();

    // merge children and add them to the Map, keyed by `id`
    for (var child of children) {
      childMap.set(child.id, _objectSpread(_objectSpread(_objectSpread({}, child), state.categories[child.id] || {}), {}, {
        parentId: id
      }));
    }

    // merge in the fetched child last
    return _objectSpread(_objectSpread({}, state), {}, {
      categories: _objectSpread(_objectSpread(_objectSpread({}, state.categories), fromPairs(childMap)), {}, {
        [id]: _objectSpread(_objectSpread(_objectSpread({}, currentCategory), payload), {}, {
          children: [...childMap.keys()],
          children_count: childMap.size
        })
      })
    });
  },
  [actions.setCurrentPage.receive]: (state, _ref2) => {
    var {
      payload,
      error
    } = _ref2;
    if (error) {
      return state;
    }
    return _objectSpread(_objectSpread({}, state), {}, {
      currentPage: payload
    });
  },
  [actions.setPrevPageTotal.receive]: (state, _ref3) => {
    var {
      payload,
      error
    } = _ref3;
    if (error) {
      return state;
    }
    return _objectSpread(_objectSpread({}, state), {}, {
      prevPageTotal: payload
    });
  }
};
export default handleActions(reducerMap, initialState);