function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo } from 'react';
import { useAppContext } from '@magento/peregrine/lib/context/app';
import { GET_STORE_BY_LAT_LNG } from '@magento/peregrine/lib/talons/App/getStoreByLatLng.gql';
var useCheckoutStoreLocatorModal = _ref => {
  var {
    triggerSearchState,
    forceAllStores
  } = _ref;
  var [{
    ipLat,
    ipLng
  }] = useAppContext();
  var [fetchStoreByLatLng, {
    data,
    loading
  }] = useLazyQuery(GET_STORE_BY_LAT_LNG, {
    fetchPolicy: 'cache-and-network'
  });
  var rad = x => {
    return x * Math.PI / 180;
  };
  useEffect(() => {
    fetchStoreByLatLng({
      variables: {
        latitude: triggerSearchState.searchedLocation ? triggerSearchState.searchedLocation.lat : ipLat,
        longitude: triggerSearchState.searchedLocation ? triggerSearchState.searchedLocation.lng : ipLng,
        radius: triggerSearchState.distanceKMInput ? parseInt(triggerSearchState.distanceKMInput.replace(/^\D+/g, '')) : 100
      }
    });
  }, [triggerSearchState]);
  var getDistance = useCallback((p1, p2) => {
    var R = 6378137;
    var dLat = rad(p2.lat - p1.lat);
    var dLong = rad(p2.lng - p1.lng);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return parseFloat((d / 1000).toFixed(2));
  }, []);
  var compare = (a, b) => {
    if (a.distance < b.distance) {
      return -1;
    }
    if (a.distance > b.distance) {
      return 1;
    }
    return 0;
  };
  var stores = useMemo(() => {
    if (data) {
      var storesWithDistance = data.storesByLatLng.stores.map(store => {
        if (store.is_active === 0 || !forceAllStores && (store === null || store === void 0 ? void 0 : store.bopis_enabled) === '0') {
          return null;
        }
        var distance = getDistance({
          lat: parseFloat(store.latitude),
          lng: parseFloat(store.longitude)
        }, {
          lat: triggerSearchState.searchedLocation ? triggerSearchState.searchedLocation.lat : ipLat,
          lng: triggerSearchState.searchedLocation ? triggerSearchState.searchedLocation.lng : ipLng
        });
        if (triggerSearchState.distanceKMInput) {
          var parseDistance = parseInt(triggerSearchState.distanceKMInput.replace(/^\D+/g, ''));
          if (parseDistance < distance) {
            return null;
          }
        }
        return _objectSpread(_objectSpread({}, store), {}, {
          distance
        });
      });
      storesWithDistance = storesWithDistance.filter(store => !!store);
      storesWithDistance.sort(compare);
      storesWithDistance = storesWithDistance.map((store, index) => {
        return _objectSpread(_objectSpread({}, store), {}, {
          marker: index + 1
        });
      });
      return storesWithDistance;
    }
    return null;
  }, [data, triggerSearchState]);
  return {
    stores,
    loading
    // fetchStoreByLatLng
  };
};

export default useCheckoutStoreLocatorModal;