import * as PageBuilder from '@magento/pagebuilder';
import * as PlainHtmlRenderer from './plainHtmlRenderer';
/**
 * This file is augmented at build time using the @magento/venia-ui build
 * target "richContentRenderers", which allows third-party modules to add
 * rendering strategies to the RichContent component.
 */

// generated by export-esm-collection-loader

export default [PageBuilder, PlainHtmlRenderer];