export default (node => {
  var banners = [];
  for (var x = 1; x <= 2; x++) {
    var _node$querySelector, _node$querySelector2, _node$querySelector3;
    var img = node.querySelector("img[data-cms-title=\"Banner ".concat(x, " Image\"]"));
    var imageSrc = img === null || img === void 0 ? void 0 : img.getAttribute('src');
    var linkData = node.querySelector("[data-cms-title=\"Banner ".concat(x, " Button Link\"]"));
    var link = linkData === null || linkData === void 0 ? void 0 : linkData.getAttribute('href');
    var linkTarget = linkData === null || linkData === void 0 ? void 0 : linkData.getAttribute('target');
    var title = (_node$querySelector = node.querySelector("[data-cms-title=\"Banner ".concat(x, " Title\"]"))) === null || _node$querySelector === void 0 ? void 0 : _node$querySelector.innerHTML;
    var description = (_node$querySelector2 = node.querySelector("[data-cms-title=\"Banner ".concat(x, " Description\"]"))) === null || _node$querySelector2 === void 0 ? void 0 : _node$querySelector2.innerHTML;
    var button = (_node$querySelector3 = node.querySelector("[data-cms-title=\"Banner ".concat(x, " Button Text\"]"))) === null || _node$querySelector3 === void 0 ? void 0 : _node$querySelector3.innerHTML;
    banners.push({
      title,
      description,
      imageSrc,
      link,
      linkTarget,
      button
    });
  }
  return {
    banners
  };
});