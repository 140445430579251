import { createActions } from 'redux-actions';
var prefix = 'CHECKOUT';
var actionTypes = ['BEGIN', 'EDIT', 'RESET', 'SET_EMAIL_TO_CART', 'SET_SUBSCRIBE_TO_NEWSLETTER', 'SET_DELIVERY_METHODS', 'SET_CART_DETAILS', 'SET_SHIPPING_ADDRESS', 'SET_AVAILABLE_SHIPPING_METHODS', 'SET_SELECTED_SHIPPING_METHOD', 'SET_AVAILABLE_PAYMENT_METHODS', 'SET_PAYMENT_INFORMATIONS', 'SET_NEXT_BUTTON_LOADER', 'SET_SHIPPING_FORM_STATE', 'SET_SHIPPING_METHODS_LOADER', 'SET_GRAND_TOTAL', 'SET_APPLIED_GIFT_CARD', 'SET_APPLIED_COUPON', 'SET_IN_STORE_PICK_UP_INFO'];

// classify action creators by domain
// e.g., `actions.order.submit` => CHECKOUT/ORDER/SUBMIT
// a `null` value corresponds to the default creator function
var actionMap = {
  BILLING_ADDRESS: {
    SUBMIT: null,
    ACCEPT: null,
    REJECT: null
  },
  SHIPPING_ADDRESS: {
    SUBMIT: null,
    ACCEPT: null,
    REJECT: null
  },
  PAYMENT_METHOD: {
    SUBMIT: null,
    ACCEPT: null,
    REJECT: null
  },
  GET_SHIPPING_METHODS: {
    REQUEST: null,
    RECEIVE: null
  },
  RECEIPT: {
    SET_ORDER: null,
    RESET: null
  },
  SHIPPING_METHOD: {
    SUBMIT: null,
    ACCEPT: null,
    REJECT: null
  },
  ORDER: {
    SUBMIT: null,
    ACCEPT: null,
    REJECT: null
  }
};
export default createActions(actionMap, ...actionTypes, {
  prefix
});