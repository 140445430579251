import { useEffect } from "react";
import PropTypes, { bool, instanceOf, object, oneOfType, string } from 'prop-types';
var useScript = props => {
  var {
    url = null,
    body = null,
    type = "application/javascript",
    element = document.getElementsByTagName("HEAD")[0],
    useCacheBust = false,
    async = true
  } = props;
  useEffect(() => {
    var script = document.createElement('script');
    if (url) {
      if (useCacheBust) {
        if (url.includes('?')) {
          var params = new URLSearchParams(url.substring(url.indexOf('?')));
          params.append("cacheBuster", new Date().getTime().toString());
          script.src = url.substring(0, url.indexOf('?')) + '?' + params.toString();
        } else {
          script.src = url + "?cacheBuster=".concat(new Date().getTime().toString());
        }
      } else script.src = url;
      script.type = type;
      script.async = async;
    } else if (body) {
      script.type = type;
      script.append(body instanceof Object ? JSON.stringify(body) : body);
    }
    script.crossOrigin = "anonymous";
    element.appendChild(script);
    return () => {
      element.removeChild(script);
    };
  }, [async, body, element, type, url, useCacheBust]);
};
var conditionallyRequiredString = (props, propName, componentName) => {
  // This component needs one of src or resource to be provided.
  if (!props.url && !props.body) {
    return new Error("Missing both 'url' and 'body' props in ".concat(componentName, ". ").concat(componentName, " needs at least one of these to be provided."));
  }
  return PropTypes.checkPropTypes({
    url: string,
    body: oneOfType([string, object])
  }, props, propName, componentName);
};
useScript.propTypes = {
  url: conditionallyRequiredString,
  body: conditionallyRequiredString,
  type: string,
  element: instanceOf(HTMLElement),
  useCacheBust: bool,
  async: bool
};
export default useScript;