import { BrowserPersistence } from "@magento/peregrine/lib/util";
var storage = new BrowserPersistence();
export var today = new Date().getDay();
var storeCode = storage.getItem('store_view_code') || process.env.STORE_VIEW_CODE;
var isStoreEn = storeCode.includes("en");
export var days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
export var convertTime = function (time) {
  var storeCode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "en";
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[0] = +time[0] % (storeCode.includes("en") ? 12 : 24) || 12; // Adjust hours
  }

  return time.join(''); // return adjusted time or original string
};

export var getTodayStoreHours = props => {
  var {
    schedule,
    day_name
  } = props;
  var todayLabel = days[today];
  if (!day_name) {
    day_name = todayLabel;
  }
  var open_time = schedule["".concat(day_name, "_open")];
  if (open_time) {
    open_time = convertTime(open_time, storeCode);
  }
  var close_time = schedule["".concat(day_name, "_close")];
  if (close_time) {
    close_time = convertTime(close_time, storeCode);
  }
  var time;
  if (open_time === 'Closed' || close_time === 'Closed') {
    if (isStoreEn) {
      time = 'Closed';
    } else {
      time = 'Fermé';
    }
  } else {
    if (isStoreEn) {
      time = "".concat(open_time, " am - ").concat(close_time, " pm");
    } else {
      time = "".concat(open_time, " - ").concat(close_time);
    }
  }
  return {
    time,
    todayLabel
  };
};
export var reformatSchedule = schedule => {
  var newSchedule = [];
  var todayLabel = days[today];
  for (var i = 0; i < days.length; i++) {
    var open_time = schedule["".concat(days[i], "_open")];
    if (open_time) {
      open_time = convertTime(open_time, storeCode);
    }
    var close_time = schedule["".concat(days[i], "_close")];
    if (close_time) {
      close_time = convertTime(close_time, storeCode);
    }
    var time = void 0;
    if (open_time === 'Closed' || close_time === 'Closed') {
      if (isStoreEn) {
        time = 'Closed';
      } else {
        time = 'Fermé';
      }
    } else {
      if (isStoreEn) {
        time = "".concat(open_time, " am - ").concat(close_time, " pm");
      } else {
        time = "".concat(open_time, " - ").concat(close_time);
      }
    }
    newSchedule.push({
      today: todayLabel === days[i],
      day: days[i],
      time
    });
  }
  return newSchedule;
};