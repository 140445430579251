import React from 'react';
import rowConfigAggregator from './ContentTypes/Row/configAggregator';
import Row from './ContentTypes/Row';
import columnConfigAggregator from './ContentTypes/Column/configAggregator';
import Column from './ContentTypes/Column';
import columnGroupConfigAggregator from './ContentTypes/ColumnGroup/configAggregator';
import ColumnGroup from './ContentTypes/ColumnGroup';
import imageConfigAggregator from './ContentTypes/Image/configAggregator';
import Image from './ContentTypes/Image';
import headingConfigAggregator from './ContentTypes/Heading/configAggregator';
import Heading from './ContentTypes/Heading';
import textConfigAggregator from './ContentTypes/Text/configAggregator';
import Text from './ContentTypes/Text';
import tabsConfigAggregator from './ContentTypes/Tabs/configAggregator';
import tabItemConfigAggregator from './ContentTypes/TabItem/configAggregator';
import blockConfigAggregator from './ContentTypes/Block/configAggregator';
import productsConfigAggregator from './ContentTypes/Products/configAggregator';
import buttonsConfigAggregator from './ContentTypes/Buttons/configAggregator';
import buttonItemConfigAggregator from './ContentTypes/ButtonItem/configAggregator';
import htmlConfigAggregator from './ContentTypes/Html/configAggregator';
import dividerConfigAggregator from './ContentTypes/Divider/configAggregator';
import videoConfigAggregator from './ContentTypes/Video/configAggregator';
import mapConfigAggregator from './ContentTypes/Map/configAggregator';
import bannerConfigAggregator from './ContentTypes/Banner/configAggregator';
import ButtonItem from './ContentTypes/ButtonItem';
import sliderConfigAggregator from './ContentTypes/Slider/configAggregator';
import heroBannerConfigAggregator from './ContentTypes/BBHeroBanner/configAggregator';
import fiftyFiftyConfigAggregator from './ContentTypes/BB5050/configAggregator';
import promoBarConfigAggregator from './ContentTypes/BBPromoBar/configAggregator';
import BBsubCategoryBlockAggregator from './ContentTypes/BBsubCategoryBlock/configAggregator';
import categoryConfigAggregator from './ContentTypes/BBCategoryBlock/configAggregator';
import blogSliderConfigAggregator from './ContentTypes/BBBlogSlider/configAggregator';
import instagramConfigAggregator from './ContentTypes/BBInstagram/configAggregator';
import inYourCartConfigAggregator from './ContentTypes/BBinYourCart/configAggregator';
import whantYouNeedConfigAggregator from './ContentTypes/BBWhantYouNeed/configAggregator';
import speciallyChosenForYouConfigAggregator from './ContentTypes/BBChosenForYou/configAggregator';
import shopByCategoryConfigAggregator from './ContentTypes/BBShopByCategory/configAggregator';
import timeLineConfigAggregator from './ContentTypes/BBTimeline/configAggregator';
import productKnowledgeTabsConfigAggregator from './ContentTypes/BBProductKnowledgeTabs/configAggregator';
import forTheBeginnerCookConfigAggregator from './ContentTypes/BBForTheBeginner/configAggregator';
import buildYourCookwareConfigAggregator from './ContentTypes/BBBuildYourCookware/configAggregator';
import careersConfigAggregator from './ContentTypes/Careers/configAggregator';

/* istanbul ignore next */
var contentTypesConfig = {
  row: {
    configAggregator: rowConfigAggregator,
    component: Row
  },
  column: {
    configAggregator: columnConfigAggregator,
    component: Column
  },
  'column-group': {
    configAggregator: columnGroupConfigAggregator,
    component: ColumnGroup
  },
  image: {
    configAggregator: imageConfigAggregator,
    component: Image
  },
  heading: {
    configAggregator: headingConfigAggregator,
    component: Heading
  },
  text: {
    configAggregator: textConfigAggregator,
    component: Text
  },
  tabs: {
    configAggregator: tabsConfigAggregator,
    component: React.lazy(() => import('./ContentTypes/Tabs'))
  },
  'tab-item': {
    configAggregator: tabItemConfigAggregator,
    component: React.lazy(() => import('./ContentTypes/TabItem'))
  },
  buttons: {
    configAggregator: buttonsConfigAggregator,
    component: React.lazy(() => import('./ContentTypes/Buttons'))
  },
  'button-item': {
    configAggregator: buttonItemConfigAggregator,
    component: ButtonItem
  },
  block: {
    configAggregator: blockConfigAggregator,
    component: React.lazy(() => import('./ContentTypes/Block'))
  },
  products: {
    configAggregator: productsConfigAggregator,
    component: React.lazy(() => import('./ContentTypes/Products'))
  },
  html: {
    configAggregator: htmlConfigAggregator,
    component: React.lazy(() => import('./ContentTypes/Html'))
  },
  divider: {
    configAggregator: dividerConfigAggregator,
    component: React.lazy(() => import('./ContentTypes/Divider'))
  },
  video: {
    configAggregator: videoConfigAggregator,
    component: React.lazy(() => import('./ContentTypes/Video'))
  },
  map: {
    configAggregator: mapConfigAggregator,
    component: React.lazy(() => import('./ContentTypes/Map'))
  },
  banner: {
    configAggregator: bannerConfigAggregator,
    component: React.lazy(() => import('./ContentTypes/Banner'))
  },
  slider: {
    configAggregator: sliderConfigAggregator,
    component: React.lazy(() => import('./ContentTypes/Slider'))
  },
  // Slide is just a banner wrapped inside a slider
  slide: {
    configAggregator: bannerConfigAggregator,
    component: React.lazy(() => import('./ContentTypes/Banner'))
  },
  hero_banner: {
    configAggregator: heroBannerConfigAggregator,
    component: React.lazy(() => import('./ContentTypes/BBHeroBanner'))
  },
  '50_50_block': {
    configAggregator: fiftyFiftyConfigAggregator,
    component: React.lazy(() => import('./ContentTypes/BB5050'))
  },
  promo_bar: {
    configAggregator: promoBarConfigAggregator,
    component: React.lazy(() => import('../../venia-ui/lib/components/PromoBar/promoBar'))
  },
  subcategory_block: {
    configAggregator: BBsubCategoryBlockAggregator,
    component: React.lazy(() => import('./ContentTypes/BBsubCategoryBlock'))
  },
  category_block: {
    configAggregator: categoryConfigAggregator,
    component: React.lazy(() => import('./ContentTypes/BBCategoryBlock'))
  },
  blog: {
    configAggregator: blogSliderConfigAggregator,
    component: React.lazy(() => import('./ContentTypes/BBBlogSlider'))
  },
  instagram: {
    configAggregator: instagramConfigAggregator,
    component: React.lazy(() => import('./ContentTypes/BBInstagram'))
  },
  in_your_cart: {
    configAggregator: inYourCartConfigAggregator,
    component: React.lazy(() => import('./ContentTypes/BBinYourCart'))
  },
  what_you_need: {
    configAggregator: whantYouNeedConfigAggregator,
    component: React.lazy(() => import('./ContentTypes/BBWhantYouNeed'))
  },
  specially_chosen_for_you: {
    configAggregator: speciallyChosenForYouConfigAggregator,
    component: React.lazy(() => import('./ContentTypes/BBChosenForYou'))
  },
  shop_by_category: {
    configAggregator: shopByCategoryConfigAggregator,
    component: React.lazy(() => import('./ContentTypes/BBShopByCategory'))
  },
  timeline: {
    configAggregator: timeLineConfigAggregator,
    component: React.lazy(() => import('./ContentTypes/BBTimeline'))
  },
  product_knowledge_tabs: {
    configAggregator: productKnowledgeTabsConfigAggregator,
    component: React.lazy(() => import('./ContentTypes/BBProductKnowledgeTabs'))
  },
  for_the_beginner_cook: {
    configAggregator: forTheBeginnerCookConfigAggregator,
    component: React.lazy(() => import('./ContentTypes/BBForTheBeginner'))
  },
  build_your_cookware_collection: {
    configAggregator: buildYourCookwareConfigAggregator,
    component: React.lazy(() => import('./ContentTypes/BBBuildYourCookware'))
  },
  careers: {
    configAggregator: careersConfigAggregator,
    component: React.lazy(() => import('./ContentTypes/Careers'))
  }
};

/**
 * Retrieve a content types configuration
 *
 * @param {string} contentType
 * @returns {*}
 */
export function getContentTypeConfig(contentType) {
  if (contentTypesConfig[contentType]) {
    return contentTypesConfig[contentType];
  }
}

/**
 * Set content types configuration with new one
 *
 * @param {string} contentType
 * @param {*} config
 * @returns {*}
 */
export function setContentTypeConfig(contentType, config) {
  return contentTypesConfig[contentType] = config;
}