function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { BrowserPersistence } from '@magento/peregrine/lib/util';
var persistence = new BrowserPersistence();
export var onSaveToStorage = _ref => {
  var {
    step,
    field,
    value
  } = _ref;
  var stepObj = {};
  if (persistence.getItem(step)) {
    stepObj = persistence.getItem(step);
    stepObj = _objectSpread(_objectSpread({}, stepObj), {}, {
      [field]: value
    });
  } else {
    stepObj = _objectSpread(_objectSpread({}, stepObj), {}, {
      [field]: value
    });
  }
  persistence.setItem(step, stepObj);
};
export var sortAvailablePaymentMethods = availablePaymentMethodsData => {
  var order = 2;
  if (availablePaymentMethodsData.filter(o => o.code === 'free').length > 0) {
    return [availablePaymentMethodsData.find(o => o.code === 'free')];
  }
  var addOrder = availablePaymentMethodsData.map(o => {
    if (o.code === 'moneris') {
      return _objectSpread(_objectSpread({}, o), {}, {
        order: 0
      });
    }
    if (o.code === 'paypal_express') {
      return _objectSpread(_objectSpread({}, o), {}, {
        order: 1
      });
    }
    if (o.code === 'sezzlepay') {
      return _objectSpread(_objectSpread({}, o), {}, {
        order: 2
      });
    }
    order = order + 1;
    return _objectSpread(_objectSpread({}, o), {}, {
      order
    });
  });
  addOrder.sort((a, b) => a.order - b.order);
  return addOrder;
};