import { useRef, useEffect } from "react";
export default function useClickOutside(_ref) {
  var {
    actionOnClickOutside
  } = _ref;
  var wrapperRef = useRef(null);
  var wrapperRef2 = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef !== null && wrapperRef !== void 0 && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (!(wrapperRef2 !== null && wrapperRef2 !== void 0 && wrapperRef2.current) || wrapperRef2 !== null && wrapperRef2 !== void 0 && wrapperRef2.current && !wrapperRef2.current.contains(event.target)) {
          actionOnClickOutside();
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);
  return {
    outWrapperRef: wrapperRef,
    outWrapperRef2: wrapperRef2
  };
}