import { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useCartContext } from '@magento/peregrine/lib/context/cart';
import { useDropdown } from '@magento/peregrine/lib/hooks/useDropdown';
import useMediaQuery from 'react-use-media-query-hook';
import { MEDIA_MAX_TABLET } from '../../../../venia-ui/lib/helpers/mediaQuery';

/**
 * Routes to hide the mini cart on.
 */
var DENIED_MINI_CART_ROUTES = [''];

/**
 *
 * @param {DocumentNode} props.queries.getItemCountQuery query to get the total cart items count
 *
 * @returns {
 *      itemCount: Number,
 *      miniCartIsOpen: Boolean,
 *      handleLinkClick: Function,
 *      handleTriggerClick: Function,
 *      miniCartRef: Function,
 *      hideCartTrigger: Function,
 *      setMiniCartIsOpen: Function
 *  }
 */
export var useCartTrigger = props => {
  var {
    setOpenStoreModal,
    setSchedule,
    schedule,
    queries: {
      getItemCountQuery
    }
  } = props;
  var isMaxTablet = useMediaQuery(MEDIA_MAX_TABLET);
  var [{
    cartId
  }] = useCartContext();
  var {
    elementRef: miniCartRef,
    expanded: miniCartIsOpen,
    setExpanded: setMiniCartIsOpen,
    triggerRef: miniCartTriggerRef
  } = useDropdown();
  var history = useHistory();
  var {
    data
  } = useQuery(getItemCountQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      cartId
    },
    skip: !cartId
  });
  var itemCount = data ? data.cart.total_quantity : 0;
  var hideCartTrigger = DENIED_MINI_CART_ROUTES.includes(history.location.pathname);
  var handleTriggerClick = useCallback(() => {
    // Open the mini cart.
    if (isMaxTablet) {
      if (setSchedule && schedule !== 'closeSchedule') {
        setSchedule('closeSchedule');
      }
      history.push('/cart');
    } else {
      setMiniCartIsOpen(isOpen => !isOpen);
    }
  }, [setMiniCartIsOpen]);
  var handleLinkClick = useCallback(() => {
    // Send the user to the cart page.
    history.push('/cart');
  }, [history]);
  return {
    handleLinkClick,
    handleTriggerClick,
    itemCount,
    miniCartIsOpen,
    miniCartRef,
    hideCartTrigger,
    setMiniCartIsOpen,
    miniCartTriggerRef
  };
};