var _excluded = ["message", "trace"];
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
export default class M2ApiResponseError extends Error {
  constructor(_ref) {
    var {
      method,
      resourceUrl,
      response,
      bodyText
    } = _ref;
    var body = "";
    var parsedBodyText;
    try {
      parsedBodyText = JSON.parse(bodyText);
      var {
          message,
          trace
        } = parsedBodyText,
        rest = _objectWithoutProperties(parsedBodyText, _excluded);
      if (message) {
        body += "Message:\n\n  ".concat(message, "\n");
      }
      var addl = Object.entries(rest);
      if (addl.length > 0) {
        body += "\nAdditional info:\n\n".concat(JSON.stringify(rest, null, 4), "\n\n");
      }
      if (trace) {
        body += "Magento PHP stack trace: \n\n".concat(trace);
      }
      body += '\n';
    } catch (e) {
      body = bodyText;
    }
    for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }
    super("".concat(method, " ").concat(resourceUrl, " responded ").concat(response.status, " ").concat(response.statusText, ": \n\n").concat(body), ...args);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, M2ApiResponseError);
    }
    this.response = response;
    this.method = method;
    this.resourceUrl = resourceUrl;

    // Preserve the original error message.
    this.baseMessage = parsedBodyText ? parsedBodyText.message : bodyText;
  }
}