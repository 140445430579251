import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { BrowserPersistence } from '@magento/peregrine/lib/util';
var storage = new BrowserPersistence();
export var getStoreCode = () => {
  var pathname = window.location.pathname || "";
  if (pathname.startsWith("/en/") || pathname === "/en") {
    return 'en';
  }
  if (pathname.startsWith("/fr/") || pathname === "/fr") {
    return 'fr';
  }
  var savedStoreCode = storage.getItem('store_view_code');
  return savedStoreCode || process.env.STORE_VIEW_CODE;
};
var changeStoreCodeStorageValues = code => {
  var storeCurrencies = {};
  var storeSecureBaseMediaUrl = {};
  AVAILABLE_STORE_VIEWS.forEach(store => {
    storeCurrencies[store.code] = store.default_display_currency_code;
    storeSecureBaseMediaUrl[store.code] = store.secure_base_media_url;
  });
  storage.setItem('store_view_code', code);
  storage.setItem('store_view_currency', storeCurrencies[code]);
  storage.setItem('store_view_secure_base_media_url', storeSecureBaseMediaUrl[code]);
};

/**
 * This component checks for use of a store code in the url that is not the
 * current base. If found, it updates the local storage values for code/currency
 * and reloads the page so that they are used in the graphQL headers.
 */
var StoreCodeRoute = () => {
  var history = useHistory();
  var location = useLocation();

  // Make sure store code is always in the url if there is pathname
  useEffect(() => {
    var pathname = window.location.pathname;
    var [, code] = pathname.split('/');
    var currentStoreCode = getStoreCode();
    var search = location.search;
    var hash = location.hash;
    var savedStoreCode = storage.getItem('store_view_code');

    // save/change needed values in localstorage
    if (!savedStoreCode || currentStoreCode !== savedStoreCode) {
      changeStoreCodeStorageValues(currentStoreCode);
    }

    // if path exists, but at the store code level
    // no store code, but something else
    if (code && !AVAILABLE_STORE_VIEWS.some(el => el.code === code)) {
      var newUrl = "".concat(pathname).concat(search).concat(hash);
      history.replace(newUrl);
    }

    // if no store code in url and no pathname,
    // basically if https://stokesstores.com, but previously FR
    // force FR in the url
    if (!code && currentStoreCode === 'fr') {
      var _newUrl = "".concat(pathname).concat(search).concat(hash);
      history.replace(_newUrl);
    }
  }, [location]);
  return null;
};
export default StoreCodeRoute;