// 301 is permanent; 302 is temporary.
var REDIRECT_CODES = new Set().add(301).add(302);
export var isRedirect = code => REDIRECT_CODES.has(code);

// Webpack injects `fetchRootComponent` as a global during the build.
// Depending on the environment, it may be a CommonJS or ES module.
var warning = () => new Error('fetchRootComponent is not defined');
var {
  fetchRootComponent = warning
} = window || {};
export var getRootComponent = fetchRootComponent.default || fetchRootComponent;