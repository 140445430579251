import { useCallback } from "react";
import { useHistory } from 'react-router-dom';
import { sendGTMEvent } from "@magento/venia-ui/lib/util/initGTM";
import { useAppContext } from "../../context/app";
import debounce from "../../util/debounce";
export var MIN_SEARCH_LENGTH = 3;
export var useSearchBar = () => {
  var history = useHistory();
  var [, {
    setOpenSearchBar,
    setSearchBarValue
  }] = useAppContext();
  var validatedSearchValue = value => {
    var trimmed = value === null || value === void 0 ? void 0 : value.trimStart();
    if ((trimmed === null || trimmed === void 0 ? void 0 : trimmed.length) >= MIN_SEARCH_LENGTH) {
      return trimmed;
    }
    return "";
  };
  var updateGtmEnteredInSearch = () => {
    sendGTMEvent({
      eventCategory: 'checkout step 1 - cart',
      eventAction: 'top search field',
      eventLabel: 'search field entered',
      event: 'genericGAEvent'
    });
  };
  var updateGtmSearch = val => {
    if (!val) return;
    sendGTMEvent({
      eventCategory: "checkout step 1 - cart",
      eventAction: "top search field",
      eventLabel: "Search performed",
      kw: val.toLowerCase(),
      event: "genericGAEvent"
    });
  };
  var debouncedExecuteSearch = useCallback(debounce(val => {
    setSearchBarValue(val);
  }, 500), []);
  var handleSuggestion = value => {
    var filteredValue = value === null || value === void 0 ? void 0 : value.trim();
    history.push({
      pathname: "/search.html",
      search: "?query=".concat(filteredValue)
    });
    updateGtmSearch(filteredValue);
    setOpenSearchBar(false);
  };
  return {
    handleSuggestion,
    validatedSearchValue,
    updateGtmSearch,
    updateGtmEnteredInSearch,
    debouncedExecuteSearch
  };
};