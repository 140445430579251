import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { GET_FREE_SHIPPING_THRESHOLD } from "@magento/venia-ui/lib/components/CartPage/cartPage.gql";
import { BrowserPersistence } from '../../util';
var persistence = new BrowserPersistence();
export default (() => {
  var {
    data: dataFreeShipping
  } = useQuery(GET_FREE_SHIPPING_THRESHOLD, {
    fetchPolicy: 'network-only',
    variables: {
      websiteId: 1
    }
  });
  var freeShippingValue = useMemo(() => {
    if (dataFreeShipping) {
      var freeShippingThreshold = dataFreeShipping.freeShippingThreshold.threshold;
      persistence.setItem('freeShippingValue', freeShippingThreshold);
      return freeShippingThreshold;
    }
    return null;
  }, [dataFreeShipping]);
  return {
    freeShippingValue
  };
});