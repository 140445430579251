function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getAdvanced, getBackgroundImages, getVerticalAlignment, getIsHidden } from '../../utils';
export default ((node, props) => {
  // Determine which node holds the data for the appearance
  var dataNode = props.appearance === 'contained' ? node.childNodes[0] : node;
  var videoOverlayColor = props.appearance === 'full-width' || props.appearance === 'full-bleed' ? node.childNodes[0] && node.childNodes[0].getAttribute('data-video-overlay-color') : dataNode.childNodes[0] && dataNode.childNodes[0].getAttribute('data-video-overlay-color');
  return _objectSpread(_objectSpread(_objectSpread(_objectSpread({
    minHeight: dataNode.style.minHeight ? dataNode.style.minHeight : null
  }, getVerticalAlignment(dataNode)), {}, {
    backgroundColor: dataNode.style.backgroundColor ? dataNode.style.backgroundColor : null
  }, getBackgroundImages(dataNode)), {}, {
    enableParallax: dataNode.getAttribute('data-enable-parallax') === '1',
    parallaxSpeed: parseFloat(dataNode.getAttribute('data-parallax-speed')),
    backgroundType: dataNode.getAttribute('data-background-type'),
    videoSrc: dataNode.getAttribute('data-video-src'),
    videoFallbackSrc: dataNode.getAttribute('data-video-fallback-src'),
    videoLoop: dataNode.getAttribute('data-video-loop') === 'true',
    videoPlayOnlyVisible: dataNode.getAttribute('data-video-play-only-visible') === 'true',
    videoLazyLoading: dataNode.getAttribute('data-video-lazy-load') === 'true',
    videoOverlayColor: videoOverlayColor || null
  }, getAdvanced(dataNode)), getIsHidden(node));
});