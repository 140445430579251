export default (node => {
  var categories = node.getAttribute('data-categories');
  var title = node.querySelector('[data-element="block_title"]');
  var _categories = new Array(JSON.parse(categories));
  var _items = null;
  if (_categories) {
    _items = _categories[0].categories;
  }
  return {
    title: title === null || title === void 0 ? void 0 : title.outerHTML,
    itemsCategory: _items
  };
});