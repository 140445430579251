/**
 * Custom type policies that allow us to have more granular control
 * over how ApolloClient reads from and writes to the cache.
 *
 * https://www.apollographql.com/docs/react/caching/cache-configuration/#typepolicy-fields
 * https://www.apollographql.com/docs/react/caching/cache-field-behavior/
 */

var typePolicies = {
  // Query/Mutation are "types" just like "Cart".
  Query: {
    fields: {
      cart: {
        // Replaces @connection(key: "Cart")
        keyArgs: () => 'Cart'
      },
      customer: {
        keyArgs: () => 'Customer'
      },
      customerCart: {
        keyArgs: () => 'Cart'
      },
      customerWishlistProducts: {
        read: existing => existing || []
      }
    }
  },
  AppliedGiftCard: {
    keyFields: ['code']
  },
  AvailablePaymentMethod: {
    keyFields: ['code']
  },
  AvailableShippingMethod: {
    // The combination of these fields makes an instance of
    // AvailableShippingMethod unique.
    keyFields: ['carrier_code', 'method_code']
  },
  Breadcrumb: {
    // Uses provided fields on the object as the `id`.
    keyFields: ['category_id']
  },
  Cart: {
    keyFields: () => 'Cart',
    fields: {
      applied_gift_cards: {
        // eslint-disable-next-line no-unused-vars
        merge(existing, incoming) {
          return incoming;
        }
      },
      available_payment_methods: {
        // eslint-disable-next-line no-unused-vars
        merge(existing, incoming) {
          return incoming;
        }
      },
      items: {
        // eslint-disable-next-line no-unused-vars
        merge(existing, incoming) {
          return incoming;
        }
      },
      prices: {
        // `merge: true` can be used for an object field.
        merge: true
      },
      shipping_addresses: {
        merge() {
          var existing = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
          var incoming = arguments.length > 1 ? arguments[1] : undefined;
          var {
            readField,
            mergeObjects
          } = arguments.length > 2 ? arguments[2] : undefined;
          // street makes these things unique
          var mergeResult = new Set();
          var streetToIndex = new Map();
          existing.forEach((existingShippingAddress, index) => {
            // Use readField instead of existingShippingAddress.street directly because it will follow cache references.
            var street = readField('street', existingShippingAddress);
            streetToIndex.set(street, index);
          });
          incoming.forEach(incomingShippingAddress => {
            var street = readField('street', incomingShippingAddress);
            if (streetToIndex.has(street)) {
              var targetIndex = streetToIndex.get(street);
              var existingShippingAddress = existing[targetIndex];
              var merged = mergeObjects(existingShippingAddress, incomingShippingAddress);
              mergeResult.add(merged);
            } else {
              // We do not have an address with this street yet, add it on to the end.
              streetToIndex.set(street, streetToIndex.size);
              mergeResult.add(incomingShippingAddress);
            }
          });
          return Array.from(mergeResult);
        }
      }
    }
  },
  Customer: {
    keyFields: () => 'Customer',
    fields: {
      addresses: {
        merge(existing, incoming) {
          return incoming;
        },
        read(cachedAddresses, _ref) {
          var {
            toReference
          } = _ref;
          if (cachedAddresses) {
            return cachedAddresses.map(address => {
              // Update v2 identifiers to new references. Previous
              // entries had `id: CustomerAddress:1` which caused
              // v3's lookup to fail. If we find a legacy id,
              // point it at the object using a reference.
              if (address.id && address.id.includes('CustomerAddress')) {
                return toReference(address.id);
              } else {
                return address;
              }
            });
          }
          // If there are no cached addresses that's fine - the schema
          // shows that it is a nullable field.
        }
      },

      orders: {
        keyArgs: ['filter'],
        items: {
          merge: true
        }
      }
    }
  },
  CustomerAddress: {
    fields: {
      street: {
        // eslint-disable-next-line no-unused-vars
        merge(existing, incoming) {
          return incoming;
        }
      }
    }
  },
  CustomerPaymentTokens: {
    keyFields: () => 'CustomerPaymentTokens',
    fields: {
      items: {
        // eslint-disable-next-line no-unused-vars
        merge(existing, incoming) {
          return incoming;
        }
      }
    }
  },
  ProductImage: {
    keyFields: ['url']
  },
  SelectedConfigurableOption: {
    // id alone is not enough to identify a selected option as it can refer
    // to something like "size" where value_id refers to "large".
    keyFields: ['id', 'value_id']
  },
  SelectedPaymentMethod: {
    keyFields: ['code']
  },
  ShippingCartAddress: {
    keyFields: ['street'],
    fields: {
      available_shipping_methods: {
        merge() {
          var existing = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
          var incoming = arguments.length > 1 ? arguments[1] : undefined;
          var {
            readField,
            mergeObjects
          } = arguments.length > 2 ? arguments[2] : undefined;
          // carrier_code + method_code makes these things unique
          var mergeResult = new Set();
          var carrierToIndex = new Map();
          existing.forEach((existingShippingMethod, index) => {
            // Use readField because it will follow cache references.
            var carrierCode = readField('carrier_code', existingShippingMethod);
            var methodCode = readField('method_code', existingShippingMethod);
            var carrierKey = "".concat(carrierCode, "|").concat(methodCode);
            carrierToIndex.set(carrierKey, index);
          });
          incoming.forEach(incomingShippingMethod => {
            // Use readField because it will follow cache references.
            var carrierCode = readField('carrier_code', incomingShippingMethod);
            var methodCode = readField('method_code', incomingShippingMethod);
            var carrierKey = "".concat(carrierCode, "|").concat(methodCode);
            if (carrierToIndex.has(carrierKey)) {
              var targetIndex = carrierToIndex.get(carrierKey);
              var existingShippingMethod = existing[targetIndex];
              var merged = mergeObjects(existingShippingMethod, incomingShippingMethod);
              mergeResult.add(merged);
            } else {
              // We do not have a method with this key yet, add it on to the end.
              carrierToIndex.set(carrierKey, carrierToIndex.size);
              mergeResult.add(incomingShippingMethod);
            }
          });
          return Array.from(mergeResult);
        }
      },
      country: {
        merge: true
      },
      region: {
        merge: true
      },
      selected_shipping_method: {
        merge: true
      }
    }
  },
  CategoryTree: {
    fields: {
      children: {
        merge(existing, incoming) {
          return incoming;
        }
      }
    }
  },
  Wishlist: {
    keyFields: _ref2 => {
      var {
        id
      } = _ref2;
      return "CustomerWishlist:".concat(id);
    },
    fields: {
      items_v2: {
        keyArgs: false,
        merge: false
      }
    }
  },
  WishlistItem: {
    keyFields: _ref3 => {
      var {
        id
      } = _ref3;
      return "CustomerWishlistItem:".concat(id);
    }
  },
  WishlistItems: {
    fields: {
      items: {
        merge: function () {
          var existing = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
          var incoming = arguments.length > 1 ? arguments[1] : undefined;
          var {
            variables
          } = arguments.length > 2 ? arguments[2] : undefined;
          if (variables) {
            var {
              currentPage = 1
            } = variables;
            // reset cache collection if we're on the first page

            if (currentPage === 1) {
              return incoming;
            }
          }
          return [...existing, ...incoming];
        }
      }
    }
  },
  SimpleWishlistItem: {
    keyFields: _ref4 => {
      var {
        id
      } = _ref4;
      return "CustomerSimpleWishlistItem:".concat(id);
    }
  },
  VirtualWishlistItem: {
    keyFields: _ref5 => {
      var {
        id
      } = _ref5;
      return "CustomerVirtualWishlistItem:".concat(id);
    }
  },
  DownloadableWishlistItem: {
    keyFields: _ref6 => {
      var {
        id
      } = _ref6;
      return "CustomerDownloadableWishlistItem:".concat(id);
    }
  },
  BundleWishlistItem: {
    keyFields: _ref7 => {
      var {
        id
      } = _ref7;
      return "CustomerBundleWishlistItem:".concat(id);
    }
  },
  GroupedProductWishlistItem: {
    keyFields: _ref8 => {
      var {
        id
      } = _ref8;
      return "CustomerGroupedProductWishlistItem:".concat(id);
    }
  },
  ConfigurableWishlistItem: {
    keyFields: _ref9 => {
      var {
        id
      } = _ref9;
      return "CustomerConfigurableWishlistItem:".concat(id);
    }
  },
  GiftCardWishlistItem: {
    keyFields: _ref10 => {
      var {
        id
      } = _ref10;
      return "CustomerGiftCardWishlistItem:".concat(id);
    }
  }
};
export default typePolicies;