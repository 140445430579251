function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { useLazyQuery } from '@apollo/client';
import { useMemo, useEffect } from 'react';
import { GET_ATS_AND_STORES_INVENTORY } from '../../../queries/getATSAndStoresInventory.gql';
var useStoreList = _ref => {
  var {
    productsInCart,
    currentStores,
    storesCodes,
    cached = true
  } = _ref;
  var [fetchInventory, {
    data: inventoryData,
    loading: inventoryLoading
  }] = useLazyQuery(GET_ATS_AND_STORES_INVENTORY, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  });
  var filteredProducts = productsInCart.filter(product => product.type !== 'GiftCardProduct');
  useEffect(() => {
    if (currentStores && currentStores.length > 0 && productsInCart) {
      var codes = storesCodes || currentStores.map(st => st.code);
      var filteredUPC = filteredProducts.map(product => product.upc_e);
      fetchInventory({
        variables: {
          stores: codes,
          products: filteredUPC
        }
      });
    }
  }, [currentStores, storesCodes, productsInCart]);
  var storesStock = useMemo(() => {
    if (inventoryData && currentStores && filteredProducts) {
      var inventories = inventoryData.getATSAndStoresInventory.productsInventories;
      return currentStores.map(store => {
        var productsStoresInventory = filteredProducts.map(product => {
          var prodStores = inventories.find(i => i.identifier === product.upc_e);
          var productInStore = prodStores && prodStores.storesInventory && prodStores.storesInventory.find(storeInv => storeInv.store === store.code);
          var productInStoreInventoryQty = productInStore === null || productInStore === void 0 ? void 0 : productInStore.qty;
          return _objectSpread(_objectSpread({}, product), {}, {
            productInStoreInventoryQty,
            askedQtyInStock: productInStoreInventoryQty >= (product.quantity || 1)
          });
        });
        return _objectSpread(_objectSpread({}, store), {}, {
          storeProductsQuantities: productsStoresInventory,
          inStock: productsStoresInventory.every(qtyObj => qtyObj.askedQtyInStock)
        });
      });
    }
    return [];
  }, [inventoryData, filteredProducts]);
  return {
    inventoryLoading,
    storesStock
  };
};
export default useStoreList;