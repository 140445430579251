var _excluded = ["unhandledErrors"];
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
/**
 * Handle unhandled errors by setting a global error state.
 *
 * "store slice" reducers may want to handle errors in a custom way, in order
 * to display more specific error states (such as form field validation).
 *
 * These reducers can indicate that an error is handled and needs no more UI
 * response, by assigning the error object to the `error` property on their
 * store slice.
 *
 * This reducer activates when the action has an `error` property. It then
 * checks each store slice for an `error` property which equals the error in
 * the action, indicating that the error has already been handled. If it
 * detects that the error is not present in any store slice, it assumes the
 * error is unhandled and pushes it into an `errors` array property on the root
 * store.
 *
 * This `errors` collection represents unhandled errors to be displayed in the
 * next render. To dismiss an error, dispatch the ERROR_DISMISS action with the
 * error as payload, and this reducer will remove it from the array.
 *
 */
import app from '../actions/app';
import errorRecord from '../../util/createErrorRecord';
var APP_DISMISS_ERROR = app.markErrorHandled.toString();

/**
 * This function returns the name of the slice for logging purposes, and
 * undefined if no slice handling this error is found. It uses
 * Object.entries() to create a [name, sliceObject] pair for each slice;
 * the iteratee only tests the value, but we destructure the name into the
 * final return value. For instance, the cart slice is represented as an
 * entry ["cart", cartState]. If cartState has any property whose value is
 * the provided error, then this function will return the string "cart".
 *
 * @param {object} fullStoreState
 * @param {Error} error
 *
 */
function sliceHandledError(state, error) {
  var foundEntry = Object.entries(state).find(_ref => {
    var [, slice] = _ref;
    return typeof slice === 'object' &&
    // A slice is considered to have "handled" the error if it
    // includes a root property (of any name) with the error as a
    // value. This is the pattern with existing reducers.
    Object.values(slice).includes(error);
  });
  if (foundEntry) {
    // Return the name of the slice.
    return foundEntry[0];
  }
}

/**
 * This reducer handles the full store state (all slices) and adds any
 * unhandled errors (as defined by the selector function
 * sliceHandledError() defined above) to a root `unhandledErrors`
 * collection. It also handles the app-level action `APP_DISMISS_ERROR` by
 * removing the passed error from that collection. Any global error UI can
 * use this action (as a click handler, for instance) to dismiss the error.
 *
 * @param {object} fullStoreState
 * @param {object} action
 */
function errorReducer(state, action) {
  var {
    unhandledErrors
  } = state;
  var {
    type,
    payload
  } = action;

  // The `error` property should be boolean and the payload is the error
  // itself, but just in case someone got that wrong...
  var error;
  if (action.error instanceof Error) {
    error = action.error;
  } else if (payload instanceof Error) {
    error = payload;
  } else {
    // No error, so nothing this reducer can do.
    return state;
  }
  if (type === APP_DISMISS_ERROR) {
    var errorsMinusDismissed = unhandledErrors.filter(record => record.error !== error);
    // If the array is the same size, then the error wasn't here
    // but it should have been!
    if (process.env.NODE_ENV === 'development' && errorsMinusDismissed.length == unhandledErrors.length) {
      console.error('Received ${APP_DISMISS_ERROR} action, but provided error "${error}" was not present in the state.unhandledErrors collection. The error object in the action payload must be strictly equal to the error to be dismissed.', error);
    }
    return _objectSpread(_objectSpread({}, state), {}, {
      unhandledErrors: errorsMinusDismissed
    });
  }

  // Handle any other action that may have produced an error.
  var sliceHandled = sliceHandledError(state, error);
  if (!sliceHandled) {
    // No one took this one. Add it to the unhandled list.
    var allErrors = [
    // Dedupe errors in case this one is dispatched repeatedly
    ...new Set(unhandledErrors).add(errorRecord(error,
    // `errorRecord()` requires the window argument for
    // testability, through injection of the
    // non-idempotent Date and Math methods for IDs.
    window,
    // Also call `errorRecord()` with the current
    // context, which is the root reducer; that enables
    // it to trim useful stack traces by omitting
    // useless lines.
    this))];
    return _objectSpread(_objectSpread({}, state), {}, {
      unhandledErrors: allErrors
    });
  }
  // If we get here, a slice DID handle it and indicated that by
  // setting it as a root property of the slice.
  return state;
}

/**
 * Wrapper function for a Redux reducer which adds an error reducer and a root
 * `unhandledErrors` collection to state. Since many reducers validate their
 * state objects, they will error if they see the "unrecognized"
 * `unhandledErrors` property. This function hides that property by extracting
 * it from state, then running the passed root reducer on the clean state, then
 * recombining the state and transforming it with the error reducer.
 *
 * @param {Function} rootReducer Original root reducer.
 */
function wrapReducerWithErrorHandling(rootReducer) {
  return function errorHandlingRootReducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments.length > 1 ? arguments[1] : undefined;
    var {
        unhandledErrors = []
      } = state,
      restOfState = _objectWithoutProperties(state, _excluded);
    var nextState = rootReducer(restOfState, action);
    nextState.unhandledErrors = unhandledErrors;
    // Apply errorReducer in the context of this root reducer,
    // so it can trim stack traces using `this`.
    return errorReducer.call(errorHandlingRootReducer, nextState, action);
  };
}

/**
 * Store enhancer which returns a StoreCreator, which accepts a
 * root reducer and an initial state and returns a new store.
 * It is in this function that we can intercept the root reducer
 * and wrap it with error handling.
 */
export default function createErrorHandlingStore(createStore) {
  return function (reducer) {
    for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }
    return createStore(wrapReducerWithErrorHandling(reducer), ...args);
  };
}