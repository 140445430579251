function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { handleActions } from 'redux-actions';
import actions from '../actions/checkout';
import BrowserPersistence from '../../util/simplePersistence';
var storage = new BrowserPersistence();
export var name = 'checkout';
var initialState = {
  email: null,
  deliveryMethods: storage.getItem('deliveryMethods'),
  cartDetails: null,
  shippingAddress: null,
  availableShippingMethods: [],
  selectedShippingMethod: null,
  availablePaymentMethods: [],
  paymentInformations: null,
  nextButtonLoader: false,
  shippingMethodsLoader: false,
  shippingFormState: {
    firstname: null,
    lastname: null,
    street1: null,
    city: null,
    region: null,
    postcode: null,
    telephone: null
  },
  appliedCoupon: null,
  appliedGiftCard: null,
  grandTotal: null,
  inStorePickupInfo: null,
  subscribeToNewsletter: false
};
var reducerMap = {
  [actions.setEmailToCart]: (state, _ref) => {
    var {
      payload
    } = _ref;
    return _objectSpread(_objectSpread({}, state), {}, {
      email: payload
    });
  },
  [actions.setSubscribeToNewsletter]: (state, _ref2) => {
    var {
      payload
    } = _ref2;
    return _objectSpread(_objectSpread({}, state), {}, {
      subscribeToNewsletter: payload
    });
  },
  [actions.setDeliveryMethods]: (state, _ref3) => {
    var {
      payload
    } = _ref3;
    return _objectSpread(_objectSpread({}, state), {}, {
      deliveryMethods: payload
    });
  },
  [actions.setCartDetails]: (state, _ref4) => {
    var {
      payload
    } = _ref4;
    return _objectSpread(_objectSpread({}, state), {}, {
      cartDetails: payload
    });
  },
  [actions.setShippingAddress]: (state, _ref5) => {
    var {
      payload
    } = _ref5;
    return _objectSpread(_objectSpread({}, state), {}, {
      shippingAddress: payload
    });
  },
  [actions.setAvailableShippingMethods]: (state, _ref6) => {
    var {
      payload
    } = _ref6;
    return _objectSpread(_objectSpread({}, state), {}, {
      availableShippingMethods: payload
    });
  },
  [actions.setSelectedShippingMethod]: (state, _ref7) => {
    var {
      payload
    } = _ref7;
    return _objectSpread(_objectSpread({}, state), {}, {
      selectedShippingMethod: payload
    });
  },
  [actions.setAvailablePaymentMethods]: (state, _ref8) => {
    var {
      payload
    } = _ref8;
    return _objectSpread(_objectSpread({}, state), {}, {
      availablePaymentMethods: payload
    });
  },
  [actions.setPaymentInformations]: (state, _ref9) => {
    var {
      payload
    } = _ref9;
    return _objectSpread(_objectSpread({}, state), {}, {
      paymentInformations: payload
    });
  },
  [actions.setNextButtonLoader]: (state, _ref10) => {
    var {
      payload
    } = _ref10;
    return _objectSpread(_objectSpread({}, state), {}, {
      nextButtonLoader: payload
    });
  },
  [actions.setShippingMethodsLoader]: (state, _ref11) => {
    var {
      payload
    } = _ref11;
    return _objectSpread(_objectSpread({}, state), {}, {
      shippingMethodsLoader: payload
    });
  },
  [actions.setAppliedCoupon]: (state, _ref12) => {
    var {
      payload
    } = _ref12;
    return _objectSpread(_objectSpread({}, state), {}, {
      appliedCoupon: payload
    });
  },
  [actions.setAppliedGiftCard]: (state, _ref13) => {
    var {
      payload
    } = _ref13;
    return _objectSpread(_objectSpread({}, state), {}, {
      appliedGiftCard: payload
    });
  },
  [actions.setGrandTotal]: (state, _ref14) => {
    var {
      payload
    } = _ref14;
    return _objectSpread(_objectSpread({}, state), {}, {
      grandTotal: payload
    });
  },
  [actions.setInStorePickUpInfo]: (state, _ref15) => {
    var {
      payload
    } = _ref15;
    return _objectSpread(_objectSpread({}, state), {}, {
      inStorePickupInfo: payload
    });
  },
  [actions.setShippingFormState]: (state, _ref16) => {
    var {
      payload
    } = _ref16;
    var {
      shippingFormState
    } = state;
    return _objectSpread(_objectSpread({}, state), {}, {
      shippingFormState: _objectSpread(_objectSpread({}, shippingFormState), payload)
    });
  },
  [actions.reset]: state => {
    return _objectSpread(_objectSpread({}, state), initialState);
  }
};
export default handleActions(reducerMap, initialState);