import BrowserPersistence from '../../util/simplePersistence';
import userActions, { signOut } from '../actions/user';
var timeouts = new Map();
var intervals = new Map();
var storage = new BrowserPersistence();
var SET_TOKEN = userActions.setToken.toString();
var CLEAR_TOKEN = userActions.clearToken.toString();
var GET_DETAILS = userActions.getDetails.request.toString();
var isSigningIn = type => type === SET_TOKEN || type === GET_DETAILS;
var isSigningOut = type => type === CLEAR_TOKEN;

/**
 * This function adheres to Redux's middleware pattern.
 *
 * @param {Store} store The store to augment.
 * @returns {Function}
 */
var scheduleSignOut = store => next => action => {
  var {
    dispatch
  } = store;
  if (isSigningIn(action.type)) {
    // `BrowserPersistence.getItem()` only returns the value
    // but we need the full item with timestamp and ttl
    var item = storage.getRawItem('signin_token');

    // exit if there's nothing in storage
    if (!item) return next(action);
    var {
      timeStored,
      ttl,
      value
    } = JSON.parse(item);
    var parsedValue = JSON.parse(value);
    var preciseTTL = ttl * 1000;
    var elapsed = Date.now() - timeStored;
    var expiry = Math.max(preciseTTL - elapsed, 0);

    // establish a sign-out routine
    var callback = () => {
      dispatch(signOut()).then(() => {
        timeouts.delete(parsedValue);
        intervals.delete(parsedValue);

        // refresh the page, important for checkout
        history.go(0);
      });
    };

    // set a timeout that runs once when the token expires
    if (!timeouts.has(parsedValue)) {
      var timeoutId = setTimeout(callback, expiry);
      timeouts.set(parsedValue, timeoutId);
    }

    // then set an interval that runs once per second
    // on mobile, the timeout won't fire if the tab is inactive
    if (!intervals.has(parsedValue)) {
      var intervalId = setInterval(() => {
        var hasExpired = Date.now() - timeStored > preciseTTL;
        if (hasExpired) callback();
      }, 1000);
      intervals.set(parsedValue, intervalId);
    }
  } else if (isSigningOut(action.type)) {
    for (var _timeoutId of timeouts) {
      clearTimeout(_timeoutId);
    }
    for (var _intervalId of intervals) {
      clearInterval(_intervalId);
    }
    timeouts.clear();
    intervals.clear();
  }
  return next(action);
};
export default scheduleSignOut;