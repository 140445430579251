export default (node => {
  var unitId = node.getAttribute('data-recommendation-id').split('%')[0];
  var title = node.getAttribute('data-title');
  var linkText = node.getAttribute('data-link-text');
  var linkHref = node.getAttribute('href');
  return {
    title,
    linkText,
    linkHref,
    unitId
  };
});