function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { handleActions } from 'redux-actions';
import { Util } from '../../index';
var {
  BrowserPersistence
} = Util;
var storage = new BrowserPersistence();
import actions from '../actions/user';
export var name = 'user';
var isSignedIn = () => !!storage.getItem('signin_token');
var _user = () => storage.getItem('currentUserData');
var initialState = {
  currentUser: {
    email: '',
    firstname: '',
    lastname: '',
    is_subscribed: '',
    customer_telephone: ''
  },
  userData: _user() && _user().payload,
  getDetailsError: null,
  isGettingDetails: false,
  isResettingPassword: false,
  isSignedIn: isSignedIn(),
  resetPasswordError: null,
  token: storage.getItem('signin_token'),
  isShareToWishlist: false,
  isSubmitSharedWishlist: false,
  listSharedWishlist: []
};
var reducerMap = {
  [actions.shareToWishlist.request]: state => _objectSpread(_objectSpread({}, state), {}, {
    isShareToWishlist: true
  }),
  [actions.shareToWishlist.receive]: (state, _ref) => {
    var {
      error
    } = _ref;
    if (error) {
      return _objectSpread(_objectSpread({}, state), {}, {
        isShareToWishlist: false
      });
    }
    return _objectSpread(_objectSpread({}, state), {}, {
      isShareToWishlist: false
    });
  },
  [actions.wishlistShare.request]: state => _objectSpread(_objectSpread({}, state), {}, {
    isSubmitSharedWishlist: true
  }),
  [actions.wishlistShare.receive]: (state, _ref2) => {
    var {
      payload,
      error
    } = _ref2;
    if (error) {
      return _objectSpread(_objectSpread({}, state), {}, {
        isSubmitSharedWishlist: false
      });
    }
    return _objectSpread(_objectSpread({}, state), {}, {
      listSharedWishlist: payload,
      isSubmitSharedWishlist: false
    });
  },
  [actions.setToken]: (state, _ref3) => {
    var {
      payload
    } = _ref3;
    return _objectSpread(_objectSpread({}, state), {}, {
      isSignedIn: true,
      token: payload
    });
  },
  [actions.clearToken]: state => {
    return _objectSpread(_objectSpread({}, state), {}, {
      isSignedIn: false,
      token: null
    });
  },
  [actions.getDetails.request]: state => {
    return _objectSpread(_objectSpread({}, state), {}, {
      getDetailsError: null,
      isGettingDetails: true
    });
  },
  [actions.getDetails.receive]: (state, _ref4) => {
    var {
      payload,
      error
    } = _ref4;
    if (error) {
      return _objectSpread(_objectSpread({}, state), {}, {
        getDetailsError: payload,
        isGettingDetails: false
      });
    }
    return _objectSpread(_objectSpread({}, state), {}, {
      currentUser: payload,
      getDetailsError: null,
      isGettingDetails: false
    });
  },
  [actions.resetPassword.request]: state => _objectSpread(_objectSpread({}, state), {}, {
    isResettingPassword: true
  }),
  // TODO: handle the reset password response from the API.
  [actions.resetPassword.receive]: (state, _ref5) => {
    var {
      payload,
      error
    } = _ref5;
    if (error) {
      return _objectSpread(_objectSpread({}, state), {}, {
        isResettingPassword: false,
        resetPasswordError: payload
      });
    }
    return _objectSpread(_objectSpread({}, state), {}, {
      isResettingPassword: false,
      resetPasswordError: null
    });
  },
  [actions.reset]: () => {
    return _objectSpread(_objectSpread({}, initialState), {}, {
      isSignedIn: false,
      token: null
    });
  }
};
export default handleActions(reducerMap, initialState);