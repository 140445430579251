function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';

/**
 * A hook that will return a invokable query that returns a Promise. Intended
 * to be used in Redux thunks that shouldn't have knowledge of queries being ran
 * but needed the ability to fetch data asyncronously inside of their actions.
 *
 * NOTE: We have discovered a potential bug in Apollo that when passing the
 * fetchQuery option "network-only", results from the cache will still be
 * returned instead of the data from the network fetch. We suggest using
 * the "no-cache" option if you expect your queries to always return fresh
 * data, or data is sensitive that you do not want to persist in the app.
 *
 * @param {DocumentNode} query - parsed GraphQL operation description
 *
 * @returns {Function} callback that runs the query and returns a Promise
 */
export var useAwaitQuery = query => {
  var apolloClient = useApolloClient();
  return useCallback(options => {
    return apolloClient.query(_objectSpread(_objectSpread({}, options), {}, {
      query
    }));
  }, [apolloClient, query]);
};