export var react_paths = [{
  en: '/faq',
  fr: '/faq'
}, {
  en: '/gift-card-balance',
  fr: '/solde-carte-cadeau'
}, {
  en: '/covid-19-updates',
  fr: '/covid-19-mise-a-jour'
}, {
  en: '/terms-of-use',
  fr: '/modalites-utilisation'
}, {
  en: '/terms-of-sale',
  fr: '/termes-et-conditions'
}, {
  en: '/privacy-and-security',
  fr: '/politique-vie-privee'
}, {
  en: '/shipping-and-returns',
  fr: '/livraison-et-retour'
}, {
  en: '/payment-options',
  fr: '/modalites-de-paiement'
}, {
  en: '/contact-us',
  fr: '/contactez-nous'
}, {
  en: '/buy-online-pick-up-in-store',
  fr: '/acheter-en-ligne-et-ramasser-en-magasin'
}, {
  en: '/about-us',
  fr: '/notre-histoire'
}, {
  en: '/sign-in',
  fr: '/sign-in'
}, {
  en: '/forgot-password',
  fr: '/forgot-password'
}, {
  en: '/create-account',
  fr: '/create-account'
}, {
  en: '/my-account',
  fr: '/my-account'
}, {
  en: '/order-history',
  fr: '/order-history'
}, {
  en: '/wishlist',
  fr: '/wishlist'
}, {
  en: '/cart',
  fr: '/cart'
}, {
  en: '/store-locator',
  fr: '/magasin-localisateur'
}, {
  en: '/store',
  fr: '/magasin'
}, {
  en: '/account/createPassword',
  fr: '/account/createPassword'
}, {
  en: '/account-information',
  fr: '/account-information'
}, {
  en: '/address-book',
  fr: '/address-book'
}, {
  en: '/checkout',
  fr: '/checkout'
}, {
  en: '/communications',
  fr: '/communications'
}, {
  en: '/saved-payments',
  fr: '/saved-payments'
}, {
  en: '/search.html',
  fr: '/search.html'
}, {
  en: '/reclaim/checkout/cart',
  fr: '/reclaim/checkout/cart'
}, {
  en: '/sezzle/payment/cancel',
  fr: '/sezzle/payment/cancel'
}, {
  en: '/sezzle/payment/complete',
  fr: '/sezzle/payment/complete'
}, {
  en: '/promotion-game',
  fr: '/promotion-game'
},
//TODO: test links redirect
{
  en: '/write-a-review',
  fr: '/write-a-review'
}, {
  en: '/code',
  fr: '/code'
}, {
  en: '/careers',
  fr: '/carrieres'
}, {
  en: '/success',
  fr: '/success'
}];