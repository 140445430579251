var getDirectionGoogleMap = _ref => {
  var {
    address,
    city,
    zipcode,
    region,
    country
  } = _ref;
  var parseAddress = address.replace('#', '');
  var searchedLocation = "".concat(parseAddress, ", ").concat(city, ", ").concat(region, ", ").concat(zipcode, ", ").concat(country);
  window.open("https://maps.google.com/?q=".concat(searchedLocation));
};
export default getDirectionGoogleMap;