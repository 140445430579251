function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }
function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }
import { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from '@magento/venia-ui/lib/drivers';
import { useQuery } from '@apollo/client';
import { useRootComponents } from '@magento/peregrine/lib/context/rootComponents';
import mergeOperations from '@magento/peregrine/lib/util/shallowMerge';
import { getRootComponent, isRedirect } from './helpers';
import DEFAULT_OPERATIONS from './magentoRoute.gql';
export var useMagentoRoute = function () {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var operations = mergeOperations(DEFAULT_OPERATIONS, props.operations);
  var {
    resolveUrlQuery
  } = operations;
  var history = useHistory();
  var {
    pathname
  } = useLocation();
  var [componentMap, setComponentMap] = useRootComponents();
  var setComponent = useCallback((key, value) => {
    setComponentMap(prevMap => new Map(prevMap).set(key, value));
  }, [setComponentMap]);
  var queryResult = useQuery(resolveUrlQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      url: pathname
    }
  });

  // destructure the query result
  var {
    data,
    error,
    loading
  } = queryResult;
  var {
    urlResolver
  } = data || {};
  var {
    id,
    redirectCode,
    relative_url,
    type,
    sku
  } = urlResolver || {};

  // evaluate both results and determine the response type
  var component = componentMap.get(pathname);
  var empty = !urlResolver || !type || id < 1;
  var redirect = isRedirect(redirectCode);
  var fetchError = component instanceof Error && component;
  var routeError = fetchError || error;
  var routeData = {};
  if (component && !fetchError && !redirect) {
    // FOUND
    routeData = component;
  } else if (routeError) {
    // ERROR
    routeData = {
      hasError: true,
      routeError
    };
  } else if (redirect) {
    // REDIRECT
    routeData = {
      isRedirect: true,
      relativeUrl: relative_url
    };
  } else if (empty && !loading) {
    // NOT FOUND
    routeData = {
      isNotFound: true
    };
  } else {
    // LOADING
    routeData = {
      isLoading: true
    };
  }

  //in case PDP doesn't have url rewrite,
  //we would load product per sku
  routeData.sku = sku;

  // fetch a component if necessary
  useEffect(() => {
    _asyncToGenerator(function* () {
      // don't fetch if we don't have data yet
      if (loading || empty) return;

      // don't fetch more than once
      if (component) return;
      try {
        var _component = yield getRootComponent(type);
        setComponent(pathname, {
          component: _component,
          id,
          type
        });
      } catch (error) {
        setComponent(pathname, error);
      }
    })();
  }, [component, empty, id, loading, pathname, setComponent, type]);

  // perform a redirect if necesssary
  useEffect(() => {
    if (routeData && routeData.isRedirect) {
      if (routeData.relativeUrl === '/') {
        history.push("/");
      } else if (history.location.pathname !== "/".concat(routeData.relativeUrl)) {
        history.push("/".concat(routeData.relativeUrl));
      }
    }
  }, [pathname, routeData]);
  return routeData;
};