import { useCallback, useRef, useState } from 'react';
import { useScrollLock } from '@magento/peregrine';
import { useEventListener } from './useEventListener';

/**
 * A React Hook for adding dropdown-related logic.
 *
 * @kind function
 *
 * @return {Dropdown} An object containing functions and values to add dropdown logic
 */
export var useDropdown = () => {
  var elementRef = useRef(null);
  var triggerRef = useRef(null);
  var [expanded, setExpanded] = useState(false);
  useScrollLock(expanded);

  // collapse on mousedown outside of the element and trigger.
  var maybeCollapse = useCallback(_ref => {
    var {
      target
    } = _ref;
    var isOutsideElement = !elementRef.current || !elementRef.current.contains(target);
    var isOutsideTrigger = !triggerRef.current || !triggerRef.current.contains(target);
    if (isOutsideElement && isOutsideTrigger) {
      setExpanded(false);
    }
  }, []);

  // add listener to document, as an effect
  useEventListener(document, 'mousedown', maybeCollapse);

  /**
   * The object returned contains the pieces needed to add the dropdown logic to your components
   *
   * @typedef Dropdown
   * @type {Object}
   * @property {Ref} elementRef - A [ref]{@link https://reactjs.org/docs/refs-and-the-dom.html} object for attaching to React elements
   * @property {Boolean} expanded - The value of the `expanded` state
   * @property {Function} setExpanded - [State Hook]{@link https://reactjs.org/docs/hooks-state.html} function for setting the expanded state
   * @property {Ref} triggerRef - A [ref]{@link https://reactjs.org/docs/refs-and-the-dom.html} object for attaching to React elements
   */
  return {
    elementRef,
    expanded,
    setExpanded,
    triggerRef
  };
};