import app from './app';
import cart from './cart';
import catalog from './catalog';
import checkout from './checkout';
import user from './user';
var reducers = {
  app,
  cart,
  catalog,
  checkout,
  user
};
export default reducers;